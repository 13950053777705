angular.module('psModule')
    .directive('psDate', function() {
        return {
            restrict: 'E',
            scope: {
                value: '='
            },
            link: function(scope, element, attrs, fn) {
                scope.$watch('value', function(nVal, oVal) {
                    if (nVal) {
                        var dateVal;
                        if (_.isArray(scope.value)) {
                            // month is zero indexed
                            dateVal = {
                                year: scope.value[0],
                                month: Math.max(0, scope.value[1] - 1),
                                day: scope.value[2]
                            };

                        } else {
                            dateVal = scope.value;
                        }
                        element.html(moment(dateVal).format('YYYY-MM-DD'));
                    }
                });
            }
        };
    })
    .directive('psDateTime', function() {
        return {
            restrict: 'E',
            scope: {
                value: '='
            },
            link: function(scope, element, attrs, fn) {
                scope.$watch('value', function(nVal, oVal) {
                    if (nVal) {
                        var dateTimeVal;
                        if (_.isArray(scope.value)) {
                            // month is zero indexed
                            dateTimeVal = {
                                year: scope.value[0],
                                month: Math.max(0, scope.value[1] - 1),
                                day: scope.value[2],
                                hours: scope.value[3],
                                minutes: scope.value[4],
                                seconds: scope.value[5]
                            };

                        } else {
                            dateTimeVal = scope.value;
                        }
                        element.html(moment(dateTimeVal).format('YYYY-MM-DD HH:mm:ss'));
                    }
                });
            }
        };
    });
