angular.module('psModule').factory('DocumentService',
    function ($resource) {
        return $resource('/ps/api/documents/:section/:id', {}, {
            'getDocuments': {method: 'GET', params: {}, isArray: true},
            'getYourDocuments': {method: 'GET', params: {section: 'yourdocuments'}, isArray: false},
            'getDocumentTypes': {method: 'GET', params: {section: 'types'}, isArray: true},
            'getUploadableDocumentTypes': {method: 'GET', params: {section: 'uploadableTypes'}, isArray: true},
            'changeVisibility': {method: 'POST', params: {section: 'visibility'}},
            'save':   {method: 'POST'},
            'viewstatus' : {method: 'GET', params: {section: 'viewstatus'}, isArray: true}
        });
    }
);
