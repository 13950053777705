angular.module('psModule').factory('CapitexSimulationService', ['$resource',
    function ($resource) {
        return $resource(null, null, {
            getSavedSimulations: {
                url: "/ps/api/capitex-simulations",
                method: "GET",
                isArray: false
            },
            createSimulations: {
                url: "/ps/api/capitex-simulations",
                method: "POST"
            },
            updateSimulations: {
                url: "/ps/api/capitex-simulations/:simulationId",
                method: "PUT"
            },
        });
    }
]);
