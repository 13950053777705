angular.module('psModule').controller('BssimulationCtrl', function($scope, $state, $filter, $resource, $uibModal, bssimulationService, salary, Message) {

    $scope.activePensionId = null;
    $scope.incomeTaxLimitBefore65 = {};
    $scope.incomeTaxLimitFrom65 = {};
    $scope.overall = {};
    $scope.pensionTypes = ['NATIONAL', 'OCCUPATIONAL', 'PRIVATE', 'UNKNOWN', 'TEMPORARY'];

    var chartGeneralMaxAge = 90;
    var chartMinAgeMinOffset = 3;

    bssimulationService.isActive().$promise.then(function (response) {
        if (!response.isActive) {
            $state.go('pensionSimulation');
        } else {
            init();
        }
    });

    $scope.showMoreInfo = function (simulation) {
        $uibModal.open({
            template:'<div><div class="modal-header"><button type="button" class="close pull-right" ng-click="$dismiss()" aria-hidden="true">&times;</button><h1 class="modal-title" translate="sim_assumption_header"></h1></div><div class="modal-body"><p translate="sim_assumption_intro"></p><p translate="sim_assumption_interest"></p><ul><li>{{annualInterestTrad | percentage:1}} <span translate="sim_assumption_for_trad"></span></li><li>{{annualInterest | percentage:1}} <span translate="sim_assumption_for_fond"></span></li></ul><p translate="sim_assumption_fee"></p><ul><li>{{percentageFeeTrad | percentage:1}} <span translate="sim_assumption_for_trad"></span></li><li>{{percentageFeeFund | percentage:1}} <span translate="sim_assumption_for_fond"></span></li></ul><p translate="sim_assumption_tax"></p><ul><li>{{taxPercentageCapital | percentage:1}} <span translate="sim_assumption_for_capital"></span></li><li>{{taxPercentagePension | percentage:1}} <span translate="sim_assumption_for_non_capital"></span></li></ul><p translate="sim_assumption_final_note"></p></div><div class="modal-footer"><button class="btn btn-default" ng-click="$dismiss()">{{\'sim_button_close\' | translate}}</button></div></div>',
            controller: 'SimulationDetailModalCtrl',
            size: 'lg',
            resolve: {
                calculationParams: function () {
                    return simulation.calculationParams;
                }
            }
        });
    };

    $scope.modify = function(pension, param, value) {
        var requests = [];
        if (!isNaN(value) || param === 'name') {
            requests.push({
                pension: pension,
                param: param,
                value: value
            });
        }

        bssimulationService.recalculate(requests, function (data) {
            $scope.simulation = data;
            prepareChart();
            updateInfoDetails($scope.simulation);
        });
    };

    $scope.addTemporaryPension = function () {
        var temporaryPension = {
            retirementAge: $scope.overall.retirementAge,
            durationInMonths: $scope.overall.duration === -1 ? -1 : $scope.overall.duration * 12,
            annualInterestBeforeRetirement: $scope.overall.annualInterestBeforeRetirementFund,
            annualInterestAfterRetirement: $scope.overall.annualInterestAfterRetirementFund
        };
        bssimulationService.add(temporaryPension, function (data) {
            $scope.simulation = data;
            prepareChart();
            updateInfoDetails($scope.simulation);
        });
    };

    $scope.remove = function(pension) {
        if (pension.pensionType !== 'TEMPORARY') {
            return;
        }

        bssimulationService.remove({momentId: pension.momentId}, function (data) {
            $scope.simulation = data;
            prepareChart();
            updateInfoDetails($scope.simulation);
        });
    };

    $scope.modifyOverallValue = function(field) {
        var range = $scope.simulation.generalLimitations[field + 'Range'];
        if (range && !isNaN($scope.overall[field])) {
            $scope.overall[field] = Math.max(Math.min($scope.overall[field], range.max), range.min);
        }

        var requests = getRequestForOverallChange(field, $scope.overall[field]);
        bssimulationService.recalculate(requests, function (data) {
            $scope.simulation = data;
            prepareChart();
            updateInfoDetails($scope.simulation);
        });
    };

    $scope.showOldSimulation = function (id) {
        $scope.dataLoaded = false;
        bssimulationService.getSavedSimulation({id: id}, function (data) {
            $scope.simulation = data;
            prepareChart();
            $scope.dataLoaded = true;
            $anchorScroll('simulationChartSection');
        });
    };

    $scope.removeOldSimulation = function (id) {
        bssimulationService.deleteSavedSimulation({id: id}, function () {
            bssimulationService.getSavedSimulations(function (data) {
                $scope.savedSimulations = data;
            });
        });
    };

    $scope.reset = function() {
        $scope.dataLoaded = false;
        bssimulationService.getInitial(function (data) {
            $scope.simulation = data;
            $scope.infoDetailsMap = getInfoDetails($scope.simulation);
            resetOverallValues();
            prepareChart();
            $scope.dataLoaded = true;
            $anchorScroll('simulationChartSection');
        });
    };

    $scope.openSaveModal = function() {
        var modal = $uibModal.open({
            template:'<div><form novalidate class="form" name="simulationForm" ng-submit="save(simulationName)"><div class="modal-header"><button type="button" class="close pull-right" ng-click="$dismiss()" aria-hidden="true">&times;</button><h1 class="modal-title" translate="sim_save_header"></h1></div><div class="modal-body save-simulation-margins"><p translate="sim_save_info"></p><div class="form-group" ng-class="{\'has-error\': simulationForm.$submitted && simulationForm.simulationName.$invalid}"><label class="col-sm-2 control-label" for="simulationName" translate="sim_saved_header_name"></label><div class="col-sm-7"><input type="text" class="form-control input-sm" id="simulationName" name="simulationName" ng-model="simulationName" required ng-minlength="3"><ng-messages for="simulationForm.simulationName.$error" ng-if="simulationForm.$submitted && simulationForm.simulationName.$invalid" role="alert"><ng-message class="error-validation" when="required"><span translate="sim_save_name_missing"></span></ng-message><ng-message class="error-validation" when="minlength"><span translate="sim_save_name_length"></span></ng-message><ng-message class="error-validation" when="uniqueName"><span translate="sim_save_name_not_unique"></span></ng-message></ng-messages></div></div></div><div class="modal-footer"><button class="btn btn-default" type="button" ng-click="$dismiss()" translate="sim_button_cancel"></button> <button class="btn btn-primary" type="submit" translate="sim_button_save"></button></div></form></div>',
            size: 'lg',
            backdrop: 'static',
            controller: function ($scope) {
                $scope.save = function (simulationName) {
                    $scope.simulationForm.simulationName.$setValidity("uniqueName", true);
                    if ($scope.simulationForm.$invalid) {
                        return;
                    }

                    bssimulationService.getSavedSimulations(function(data) {
                        var simulationWithSameName = _.first(_.where(data, {
                            name: simulationName
                        }));
                        if (simulationWithSameName) {
                            $scope.simulationForm.simulationName.$setValidity("uniqueName", false);
                            return;
                        }

                        $scope.$close(simulationName);
                    });
                }
            }
        });

        modal.result.then(function (name) {
            $scope.simulation.loadedSimulationName = name;
            bssimulationService.saveCurrentSimulation($scope.simulation, function () {
                bssimulationService.getSavedSimulations(function (data) {
                    $scope.savedSimulations = data;
                });
            });
        })
    };

    function resetOverallValues() {
        $scope.overall = {
            retirementAge: !!$scope.simulation.calculationParams['com_default_pension_age'] ? $scope.simulation.calculationParams['com_default_pension_age'] / 1.0 : 65,
            duration: 5,
            annualInterestBeforeRetirementTrad: !!$scope.simulation.calculationParams['prp_annual_interest_trad'] ? $scope.simulation.calculationParams['prp_annual_interest_trad'] : 0,
            annualInterestBeforeRetirementFund: !!$scope.simulation.calculationParams['prp_annual_interest'] ? $scope.simulation.calculationParams['prp_annual_interest'] : 0,
            annualInterestAfterRetirementTrad: 0,
            annualInterestAfterRetirementFund: 0
        };
    }

    function getRequestForOverallChange(field, value) {
        var requests = [];
        _.forEach($scope.pensionTypes, function (type) {
            var pensions = _.filter($scope.simulation.pensionsByType[type], function (p) {
                if (field === 'annualInterestBeforeRetirementTrad' || field === 'annualInterestAfterRetirementTrad') {
                    return p.managementType === 'TRAD';
                } else if (field === 'annualInterestBeforeRetirementFund' || field === 'annualInterestAfterRetirementFund') {
                    return p.managementType === 'FUND';
                } else {
                    return true;
                }
            });
            for (var j = 0; j < pensions.length; j++) {
                requests.push({
                    pension: pensions[j],
                    param: field,
                    value: value
                })
            }
        });
        return requests;
    }

    function getChartMaxAge(pensions) {
        var maxAge = 60;
        for (var j = 0; j < pensions.length; j++) {
            if (pensions[j].duration.value === -1) {
                return chartGeneralMaxAge;
            }
            maxAge = Math.max(pensions[j].retirementAge.value + pensions[j].duration.value, maxAge);
        }
        return Math.min(maxAge, chartGeneralMaxAge);
    }

    function getChartMinAge(pensions, age) {
        var minAge = chartGeneralMaxAge;
        for (var j = 0; j < pensions.length; j++) {
            minAge = Math.min(pensions[j].retirementAge.value, minAge);
        }
        minAge = minAge - chartMinAgeMinOffset;
        return !age ? minAge : Math.max(minAge, age);
    }

    function getMaxAmount(pensions) {
        var maxAmount = 0;
        for (var j = 0; j < pensions.length; j++) {
            maxAmount = Math.max(pensions[j].monthlyAmount, maxAmount);
        }
        return maxAmount;
    }

    function getChartTypeData(pensionType) {
        if (pensionType === 'NATIONAL') {
            return {
                index: 4,
                color: "#EBB04D"
            };
        } else if (pensionType === 'OCCUPATIONAL') {
            return {
                index: 3,
                color: "#4968C0"
            };
        } else if (pensionType === 'PRIVATE') {
            return {
                index: 2,
                color: "#77A7F6"
            };
        } else if (pensionType === 'TEMPORARY') {
            return {
                index: 1,
                color: "#A0DDFF"
            };
        } else {
            return {
                index: 0,
                color: "#FFDB00"
            };
        }
    }

    function getTaxInfoColor(taxLimit, highestTotalMonthlyAmount) {
        return highestTotalMonthlyAmount >= taxLimit ? '#f60000' : '#00f600';
    }

    function getChartCategories(pensionLowerLimit, pensionUpperLimit) {
        var chartCategories = [];
        for (var i = pensionLowerLimit; i <= pensionUpperLimit; i++) {
            chartCategories.push(i + "");
        }
        return chartCategories;
    }

    function getChartSeries(pensionsByType, chartMinAge, chartMaxAge) {
        var chartSeries = [];
        var pensionMaxDuration = chartMaxAge - chartMinAge;

        _.forEach($scope.pensionTypes, function (type) {
            var pensions = pensionsByType[type];
            if (!pensions || pensions.length === 0) {
                return;
            }

            var chartTypeData = getChartTypeData(type);

            var dataArray = [];
            for (var k = 0; k <= pensionMaxDuration; k++) {
                dataArray[k] = 0;
            }
            for (var j = 0; j < pensions.length; j++) {
                if (!pensions[j].monthlyAmount || !pensions[j].duration.value || !pensions[j].retirementAge.value) {
                    continue;
                }

                var duration = pensions[j].duration.value === -1 ? 99 : pensions[j].duration.value;
                var startPayment = pensions[j].retirementAge.value - chartMinAge;
                var endPayment = Math.min(startPayment + duration - 1, pensionMaxDuration);
                for (k = startPayment; k <= endPayment; k++) {
                    dataArray[k] = dataArray[k] + pensions[j].monthlyAmount;
                }
            }

            var chartData = {
                name: 'sim_pension_type-' + type,
                data: dataArray,
                color: chartTypeData['color'],
                index: chartTypeData['index'],
                stack: 'first'
            };

            chartSeries.push(chartData);
        });

        return chartSeries;
    }

    function updateTaxLimits(allPensions, calculationParams) {
        var maxAmount = getMaxAmount(allPensions);
        $scope.incomeTaxLimitBefore65.value = !!calculationParams && !!calculationParams['com_income_tax_breakpoint_before_65'] ? calculationParams['com_income_tax_breakpoint_before_65'] / 12.0 : 99999999;
        $scope.incomeTaxLimitBefore65.color = getTaxInfoColor($scope.incomeTaxLimitBefore65.value, maxAmount);
        $scope.incomeTaxLimitFrom65.value = !!calculationParams && !!calculationParams['com_income_tax_breakpoint_from_65'] ? calculationParams['com_income_tax_breakpoint_from_65'] / 12.0 : 99999999;
        $scope.incomeTaxLimitFrom65.color = getTaxInfoColor($scope.incomeTaxLimitFrom65.value, maxAmount);
    }

    function prepareChart() {
        var pensionsForChart = [];
        var pensionsByType = {};
        _.forEach($scope.pensionTypes, function (type) {
            var pensions = $scope.simulation.pensionsByType[type];
            if (!pensions || pensions.length === 0) {
                return;
            }

            pensions = _.filter(pensions, function (p) {
                return p.monthlyAmount && p.retirementAge && p.retirementAge.value && p.duration && p.duration.value;
            });

            pensionsByType[type] = pensions;
            pensionsForChart = pensionsForChart.concat(pensions);
        });

        var chartMinAge = getChartMinAge(pensionsForChart, $scope.simulation.age);
        var chartMaxAge = getChartMaxAge(pensionsForChart);

        updateTaxLimits(pensionsForChart, $scope.simulation.calculationParams);

        $scope.chartCategories = getChartCategories(chartMinAge, chartMaxAge);
        $scope.chartSeries = getChartSeries(pensionsByType, chartMinAge, chartMaxAge);
        $scope.$broadcast('redraw');
    }

    // Note, we fetch the info details from the initial simulation since the information i should
    // show the data as it was before simulation changes were made.
    function updateInfoDetails(data) {
        _.forEach($scope.pensionTypes, function(type) {
            var pensions = data.pensionsByType[type];
            for (var j = 0; j < pensions.length; j++) {
                pensions[j].infoDetails = $scope.infoDetailsMap.get(pensions[j].momentId);
            }
        });
    }

    function getInfoDetails(data) {
        var infoDetails = new Map();
        _.forEach($scope.pensionTypes, function (type) {
            var pensions = data.pensionsByType[type];
            for (var j = 0; j < pensions.length; j++) {
                infoDetails.set(pensions[j].momentId, pensions[j].infoDetails);
            }
        });
        return infoDetails;
    }

    function init() {
        $scope.dataLoaded = false;
        bssimulationService.getInitial(function (data) {
            $scope.simulation = data;
            $scope.infoDetailsMap = getInfoDetails($scope.simulation);
            resetOverallValues();
            prepareChart();
            $scope.dataLoaded = true;
        });

        bssimulationService.getSavedSimulations(function (data) {
            $scope.savedSimulations = data;
        });

        if (!salary.data || !salary.data.yearlyAmount) {
            Message.error('ps_missingCustomerInfoMessage_title', 'ps_missingCustomerInfoMessage_text', 'noSalaryErr');
        }
    }

});
