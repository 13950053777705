angular.module('psModule').controller('FbeskedCtrl', function ($rootScope, $scope, $q, $timeout, $translate, $location, $document, benefitService, StorageService, Message, DateService, CapitexSimulationService,TokenService) {
    setupCapitexBenefitServiceIntegration();
    const loggedInUser = TokenService.getLoggedInUser();
    $scope.userId = loggedInUser.userId;
    $scope.userName = loggedInUser.userName;
    $scope.dataLoaded = false;
    $scope.fbesked = {};
    $scope.optionId = StorageService.getOptionId();
    $scope.performingCapitexOperation = false;
    $scope.capitexSimulations = CapitexSimulationService.getSavedSimulations();
    $scope.capitexLoading = false;
    $scope.setCapitexLoading = (loading) => {
        this.capitexLoading = loading.detail;
        console.log("Capitex loading:", this.capitexLoading);
    }
    $scope.saveSimulations = (simulationsCustomEvent) => {
        const simulationsPayload = simulationsCustomEvent.detail;
        $scope.performingCapitexOperation = true;
        let savePromise;
        if ($scope.capitexSimulations.id) {
            savePromise = CapitexSimulationService.updateSimulations({simulationId:$scope.capitexSimulations.id}, {simulations: simulationsPayload}).$promise
        } else {
            savePromise = CapitexSimulationService.createSimulations({simulations: simulationsPayload}).$promise;
        }

        savePromise
            .then((r) => {
                $scope.capitexSimulations = CapitexSimulationService.getSavedSimulations();
            })
            .finally(() => $scope.performingCapitexOperation = false);
    };

    function fetchAndSetCalcParam() {

        benefitService.getFbesked().then(function (fbesked) {
            $scope.fbesked = fbesked;
            $scope.momentNotes = getAllMomentNotes(fbesked);
            $scope.momentNotesSortedKeys = _.keys($scope.momentNotes).sort((a, b) => {
                const aIsNumeric = a.match(/^\d+$/);
                const bIsNumeric = b.match(/^\d+$/);
                const aIsNote = a.match(/^N\d+$/);
                const bIsNote = b.match(/^N\d+$/);

                if (aIsNumeric && bIsNumeric) {
                    return Number(a)-Number(b);
                } else if (aIsNote && bIsNote) {
                    return Number(a.substr(1))-Number(b.substr(1));
                } else {
                    return aIsNumeric ? -1 : 1;
                }
            });

            $scope.hasOverviewDisplay = hasOverviewDisplay(fbesked);

            $scope.sum = function (belopp) {
                return _.reduce(belopp, function (memo, fbelopp) {
                    return memo + fbelopp.ersattning.belopp.value;
                }, 0);
            };

            $scope.civilstand = fbesked.assumptions.civilstand;
            $scope.inRelationship = $scope.civilstand === 'Gift' || $scope.civilstand === 'Partner';

            $scope.hasChildren = !_.isEmpty(fbesked.assumptions.barn);
            if ($scope.hasChildren) {
                childrenText(fbesked.assumptions).then(function (text) {
                    $scope.childrenText = text;
                });
            }

            $scope.manadslon = fbesked.assumptions.dateradArslon && fbesked.assumptions.dateradArslon.value && fbesked.assumptions.dateradArslon.value > 0 ? fbesked.assumptions.dateradArslon.value / 12 : null;
            if (!$scope.manadslon) {
                Message.error('ps_missingCustomerInfoMessage_title', 'ps_missingCustomerInfoMessage_text', 'noSalaryErr');
            }

            $scope.dataLoaded = true;
        }, function () {
            Message.error('ps_missingCustomerInfoMessage_title','ps_missingCustomerInfoMessage_text', 'noSalaryErr');
        });
    }

    fetchAndSetCalcParam();

    // Need to refresh data when language changes
    var cleanUpFunc = $rootScope.$on('$translateChangeEnd', function() {
        fetchAndSetCalcParam();
    });

    // Need to remove eventlistener when leaving the scope
    $scope.$on('$destroy', function() {
        cleanUpFunc();
    });

    $scope.hasContent = function (fbesked, category, subcat) {
        let categoryMapElement = fbesked.categoryMap[category];
        if(categoryMapElement) {
            let subCategoryMapElement = categoryMapElement.subCategoryMap[subcat];
            if(subCategoryMapElement) {
                let forsakringsbeloppList = subCategoryMapElement.forsakringsbeloppList;
                if(forsakringsbeloppList) {
                    return forsakringsbeloppList.length > 0;
                }
            }
        }
        return false;
    };

    function getAllMomentNotes(fbesked) {
        var momentNotes = {};
        var forsakringsbelopp = getAllForsakringsbelopp(fbesked);
        var footnotes = _.compact(_.map(forsakringsbelopp, function (f) { return f.footnotes; }));
        for (var i = 0; i < footnotes.length; i++) {
            var keys = Object.keys(footnotes[i]);
            for (var j = 0; j < keys.length; j++) {
                if (!momentNotes.hasOwnProperty(keys[j])) {
                    momentNotes[keys[j]] = footnotes[i][keys[j]];
                }
            }
        }
        return momentNotes;
    }

    function hasOverviewDisplay(fbesked) {
        try {
            let forsakringsbelopp = getAllForsakringsbelopp(fbesked);
            let overviews = _.compact(_.map(forsakringsbelopp, function (f) { return f.overviewDisplay; }));
            for (var i = 0; i < overviews.length; i++) {
                let keys = Object.keys(overviews[i]);
                if(keys && keys.length > 0) {
                    return true;
                }
            }
        }
        catch(err) {
            // silent
        }
        return false;
    }

    function getAllForsakringsbelopp(fbesked) {
        var forsakringsbelopp = [];
        Object.keys(fbesked.categoryMap).forEach(function (key) {
            var subCategoryMap = fbesked.categoryMap[key].subCategoryMap;
            Object.keys(subCategoryMap).forEach(function (subKey) {
                forsakringsbelopp = forsakringsbelopp.concat(subCategoryMap[subKey].forsakringsbeloppList);
            });
        });
        return forsakringsbelopp;
    }

    function childrenText(assumptions) {

        var translationKeys = [
            "ps_fbesked_calcparams_barn_years",
            "ps_fbesked_calcparams_unknown_age",
            "ps_fbesked_calcparams_barn_legalStatus-Gemensamt-singularis",
            "ps_fbesked_calcparams_barn_legalStatus-Gemensamt-pluralis",
            "ps_fbesked_calcparams_barn_legalStatus-Sarkull-singularis",
            "ps_fbesked_calcparams_barn_legalStatus-Sarkull-pluralis",
            "ps_fbesked_calcparams_barn_legalStatus-Partners-singularis",
            "ps_fbesked_calcparams_barn_legalStatus-Partners-pluralis"
        ];

        return $translate(translationKeys).then(function(translations) {

            var ageTranslated = translations['ps_fbesked_calcparams_barn_years'];
            var unknownAgeTranslated = translations['ps_fbesked_calcparams_unknown_age'];

            function joinBy(delim) {
                return function(memo, part) { return memo + delim + part; };
            }

            function ageTxt(children) {
                var ageDescriptions = _.map(children, function(child) {
                    return !!child.fodelsedatum ? DateService.getAge(child.fodelsedatum) + ' ' + ageTranslated : unknownAgeTranslated;
                });
                return _.chain(ageDescriptions)
                    .reduce(joinBy(', '))
                    .value();
            }

            function legalStatusTxt(children, key) {
                var tsKey = children.length === 1 ? 'ps_fbesked_calcparams_barn_legalStatus-'+ key +'-singularis' :
                    'ps_fbesked_calcparams_barn_legalStatus-'+ key +'-pluralis';
                return children.length + " " + translations[tsKey] + ' ' + ageTxt(children);
            }

            var byLegalStatus = _.groupBy(assumptions.barn, 'legalStatus');
            var byLegalStatusTxt = _.chain(byLegalStatus)
                .map(legalStatusTxt)
                .reduce(joinBy(', '))
                .value();

            return $translate('ps_fbesked_calcparams_barn', {
                total: assumptions.barn.length,
                byLegalStatus: byLegalStatusTxt
            });
        });
    }

    function setupCapitexBenefitServiceIntegration() {
        // Integration with Capitex Benefit Service
        // ========================================
        // The fbesked view integrates with the Capitex Benefit Service using the custom elements provided by it.
        // The custom elements are included by including the main.js file published by Capitex Benefit Service.
        // The main.js file is lazy loaded in the router state configuration (see setup of fbesked state at
        // PSFrontend/src/main/angularjs/app.js:50).
        $scope.capitexRequest = undefined;
        $scope.useCapitex = capitexIsActive()
        if ($scope.useCapitex) {
            $scope.performingCapitexOperation = true;
            benefitService.getBenefitSummaryRequestWrapper()
                .then(request => {
                    console.log("Capitex Benefit Service request: ", request);
                    $scope.capitexRequest = request;
                })
                .finally(() => $scope.performingCapitexOperation = false);
        }
    }

    function capitexIsActive() {
        return ($scope.$root.config && $scope.$root.config.toggles && $scope.$root.config.toggles.USE_CAPITEX === true) || $location.search().useCapitex === '1';
    }
});


