angular.module('psModule').directive('psFactsheetLink', function($rootScope, $filter) {

    var translate = $filter('translate');

    var urlTemplate = _.template($rootScope.config.innehav.factsheetUrlTemplate);

    return {
        restrict: 'E',
        template: '<span><a ng-if="url" class="hidden-xs hidden-sm" ng-href="{{url}}" target="_blank" uib-tooltip="{{\'ps_fund_show_factsheet\' | translate}}"><span ng-transclude></span></a><a ng-if="url" class="hidden-md hidden-lg" ng-href="{{url}}" target="_blank"><span ng-transclude></span></a></span>',
        transclude: true,
        scope: {
            fund: "="
        },
        link: function(scope, element, attrs, fn) {
            if (scope.fund) {
                scope.url = urlTemplate(scope.fund);
            }
        }
    };

});
