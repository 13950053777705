angular.module('psModule')
    .service('StorageService', function ($window, $cookies) {
        const get = (name) => $window.sessionStorage[name];
        const set = (name, val) => {
            if (val === undefined || val === null) {
                delete $window.sessionStorage[name];
            } else {
                $window.sessionStorage[name] = val;
            }
        }
        const clear = (name) => delete $window.sessionStorage[name];

        const auth = 'authToken';
        const ps = 'psToken';
        const id = 'optionId';

        return {
            getAuthToken: () => get(auth),
            setAuthToken: (token) => set(auth, token),
            getPsToken: () => get(ps),
            setPsToken: (token) => set(ps, token),
            getOptionId: () => get(id),
            setOptionId: (val) => set(id, val),
            clearOptionId: () => clear(id),
            clear: () => {
                clear(auth);
                clear(ps);
                clear(id);
            }
        };
    })
    .service('ConfigService', ($http, base64, $rootScope, StorageService, TokenService, LogoutService, $q) => {

        let configPromise;

        const removeSpinner = () => {
            const loadingClass = 'deferred-bootstrap-loading';
            const bodyElement = angular.element(document.body);
            bodyElement.removeClass(loadingClass);
        };

        const updateConfig = (token, source) => {

            var req = {
                method: 'GET',
                url: '/ps/api/system/init-config',
                params: { source },
                headers: {
                    authorization: token
                }
            };

            // Pending config
            delete $rootScope.user;
            StorageService.setAuthToken(undefined);

            configPromise = $http(req).then(function(response) {
                if (response.status >= 400 || !response.data.authToken) {
                    LogoutService.logout();
                }
                const config = $rootScope.config = response.data;
                const userConfig = config.authToken.split('.')[0];
                const userConfigJson = base64.urldecode(userConfig);

                $rootScope.user = JSON.parse(userConfigJson);
                StorageService.setAuthToken(config.authToken);
                $rootScope.$emit('$rootScope:$emit:config-ok');
                return config;
            }, (err) => {
                StorageService.clear();
                return $q.reject(err);
            }).finally(removeSpinner);
        };

        return {
            init: (token, source) => {
                if (token || TokenService.hasMaxSecurityAuth()) {
                    if (token) {
                        StorageService.setPsToken(token);
                    }
                    updateConfig(token, source);
                } else {
                    removeSpinner();
                }
            },
            config: () => $rootScope.config,
            pendingConfig: () => configPromise,
            isPending: () => !!configPromise && !$rootScope.user
        };
    });
