angular.module('psModule')
    .directive('psInfoLink', function() {
       return {
           restrict: 'E',
           scope: {
               url: '@',
               linkText: '@',
               text: '@'
           },
           template:'<dt><a translate="{{linkText}}" ng-href="{{url}}" target="_blank"></a></dt><dd translate="{{text}}"></dd>'
       };
    });
