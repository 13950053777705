angular.module('psModule')
    .directive('psLanguageSelector', function() {
        return {
            restrict: 'E',
            replace: true,
            template:'<li uib-dropdown><a uib-dropdown-toggle data-toggle="dropdown"><img ng-src="{{getCurrentFlagIcon()}}" class="flag-icon" alt="Flag icon"> <span translate="ps_language_short"></span> <i class="fa fa-caret-down"></i></a><ul class="dropdown-menu" uib-dropdown-menu role="menu"><li ng-click="setLanguage(\'en\')"><a><img src="/ps/resources/img/icons/flag_unitedkingdom_x16.png" alt="Flag icon"> <span translate="ps_english_nav"></span></a></li><li ng-click="setLanguage(\'sv\')"><a><img src="/ps/resources/img/icons/flag_sweden_x16.png" alt="Flag icon"> <span translate="ps_swedish_nav"></span></a></li></ul></li>',
            controller: 'LanguageNavCtrl'
        };
    })
    .controller('LanguageNavCtrl', function LanguageNavCtrl($scope, $translate, $locale, $rootScope, AlertService) {

        $scope.setLanguage = function(key) {
            $translate.use(key);
            setAlertMessage(key)

            // Update the language of the datepicker
            if (key === 'sv') {
                $locale.DATETIME_FORMATS.MONTH = ["januari", "februari", "mars", "april", "maj", "juni", "juli", "augusti", "september", "oktober", "november", "december"];
                $locale.DATETIME_FORMATS.SHORTDAY = ["s\u00f6n", "m\u00e5n", "tis", "ons", "tors", "fre", "l\u00f6r"];
            } else {
                $locale.DATETIME_FORMATS.MONTH = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                $locale.DATETIME_FORMATS.SHORTDAY = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            }
        };

        $scope.getCurrentFlagIcon = function() {
            return $translate.use() === 'sv' ? '/ps/resources/img/icons/flag_sweden_x16.png' : '/ps/resources/img/icons/flag_unitedkingdom_x16.png';
        };

        function setAlertMessage(key) {
            var alertResponse = AlertService.getMessage();
            alertResponse.$promise.then(
                function (response) {
                    $rootScope.alertMessage = response.alertMessage;
                    if(key === 'en') {
                        $rootScope.alertInfo = response.alertMessage.messageEN;
                    } else {
                        $rootScope.alertInfo = response.alertMessage.messageSV;
                    }
                });
        }
    });
