angular.module('psModule').factory('bssimulationService', ['$resource',
    function ($resource) {
        return $resource(null, null, {
            'getInitial': {
                url: '/ps/api/bssimulation/',
                method: 'GET',
                isArray: false
            },
            'isActive': {
                url: '/ps/api/bssimulation/active',
                method: 'GET',
                transformResponse: function (data) {
                    return {isActive: data === 'true'};
                }
            },
            'recalculate': {
                url: '/ps/api/bssimulation/recalculate',
                method: 'POST',
                isArray: false
            },
            'add': {
                url: '/ps/api/bssimulation/pension',
                method: 'POST',
                isArray: false
            },
            'remove': {
                url: '/ps/api/bssimulation/pension/:momentId',
                method: 'DELETE',
                isArray: false
            },
            'getSavedSimulations': {
                url: '/ps/api/bssimulation/saved',
                method: 'GET',
                isArray: true
            },
            'getSavedSimulation': {
                url: '/ps/api/bssimulation/saved/:id',
                method: 'GET',
                isArray: false
            },
            'deleteSavedSimulation': {
                url: '/ps/api/bssimulation/saved/:id',
                method: 'DELETE',
                isArray: false
            },
            'saveCurrentSimulation': {
                url: '/ps/api/bssimulation/saved',
                method: 'POST',
                isArray: false
            }
        });
    }
]);
