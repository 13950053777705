angular.module('psModule')
    .controller('FundDetailsCtrl', function($scope, $transition$, $sce, $rootScope) {
        var urlTemplate = _.template($rootScope.config.innehav.detailsUrlTemplate);

        $scope.fundInfo = {
            meta: {}
        };

        if($transition$.params().sid && $transition$.params().uid) {
            $scope.fundInfo.meta.securityId = $transition$.params().sid;
            $scope.fundInfo.meta.universeId = $transition$.params().uid;
            $scope.fundInfo.url = $sce.trustAsResourceUrl(urlTemplate($scope.fundInfo.meta));
        } else {
            $scope.fundInfo.missing = true;
        }
    });
