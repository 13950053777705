angular.module('psBankId')
    .controller('BankIdSignController', function ($scope, $rootScope, $element, BankIdService, $interval, $timeout, $filter, $log, $window) {
        var pollSleepMillis = $scope.otherDevice ? 9000 : 3000;
        var pollIntervalMillis = 3000;
        var pollInterval;
        var pollTimer;

        $scope.inProgress = false;
        $scope.pollInProgress = false;
        $scope.noClient = false;

        $scope.sign = function () {

            if($scope.isApproved()){
                $scope.inProgress = true;
                $scope.onStarted();

                hideErrorMessage();
                displayPrefixedInfoMessage({status: 'OUTSTANDING_TRANSACTION'});

                var signatureInput = {signData: $scope.signData(), context: $scope.context.toUpperCase()};

                BankIdService.sign(signatureInput, function (response) {
                    if (response.isError) {
                        handleErrorResponse(response.status);
                        return;
                    }

                    var collectRequest = {
                        orderResponse: response.payload,
                        signatureInput: signatureInput
                    };

                    if ($scope.openOnThis) {
                        $scope.collectRequest = collectRequest; // Save if need to retryPoll using restartPoll();
                        launchBankIdApp(collectRequest.orderResponse);
                    }
                    else if(!$rootScope.user.broker) {
                        // Display QR code ASAP
                        BankIdService.signQrData(collectRequest, function (response) {
                            displayQrCode(response.payload);
                        });
                    }

                    startPoll(collectRequest);
                }, function (response) {
                    handleErrorResponse(response.data.status);
                });

            }
        };

        $scope.$on('$destroy', function () {
            cancelPoll();
        });

        $scope.disable = function () {
            return $scope.inProgress || $scope.disabled();
        };

        $scope.disableLaunchBankID = function () {
            return $scope.pollInProgress || $scope.disabled() || $scope.otherDevice;
        };

        // If response from polling in collect() results in NO_CLIENT then handleNoClient() is called and
        // user can retry launch of BankID app on computer (not otherDevice) by calling this function (from button click).
        // In this case we must read the previously saved collectRequest from sign() as input for startPoll()
        $scope.restartPoll = function () {
            if ($scope.collectRequest) {
                startPoll($scope.collectRequest);
            }
        };

        function startPoll(collectRequest) {
            $scope.pollInProgress = true;
            pollTimer = $timeout(function () {
                pollInterval = $interval(function () {
                    BankIdService.signCollect(collectRequest, function (response) {
                        var handler = statusHandlers[response.status];
                        if (handler) {
                            handler(response);
                        } else {
                            handleErrorResponse(response.status);
                        }
                    }, function (response) {
                        cancelPoll();
                        displayErrorMessage(response.data.status);
                    });
                    if (!$scope.openOnThis && !$rootScope.user.broker) {
                        BankIdService.signQrData(collectRequest, function (response) {
                            displayQrCode(response.payload);
                        }, function (response) {
                            cancelPoll();
                            displayErrorMessage(response.data.status);
                        });
                    }
                }, pollIntervalMillis);
            }, pollSleepMillis);
        }

        function launchBankIdApp(orderResponse) {
            $scope.bankIdUrl = 'bankid:///?autostarttoken=' + orderResponse.autoStartToken + '&redirect=';

            if ($scope.isTouchDevice || (window.navigator.userAgent && window.navigator.userAgent.toLowerCase().indexOf("chrome") > -1)) {
                $window.location = $scope.bankIdUrl;
            } else {
                $element.find('#bankid-frame').attr('src', $scope.bankIdUrl);
            }
        }

        function handleComplete(response) {

            cancelPoll();
            var ipAddress = _.find(response.payload.attributes, function( attribute ) {
                    return 'ipAddress' === attribute.name;
                }) || {value:''};
            $scope.onComplete({
                signature: response.payload.validationInfo.signature,
                signatureVisibleData: response.signatureVisibleData,
                signatureToken: response.signatureToken,
                bankIdLogEntryId: response.bankIdLogEntryId,
                ipAddress: ipAddress.value
            });
        }

        function handleNoClient(response) {
            if (!$scope.otherDevice) {
                cancelPoll();
                displayInfoMessage(response.status);
                $scope.noClient = true;
                $scope.inProgress = true;
            }
        }

        function cancelTimers(){
            if (angular.isDefined(pollInterval)) {
                $interval.cancel(pollInterval);
                pollInterval = undefined;
            }
            if(angular.isDefined(pollTimer)){
                $timeout.cancel(pollTimer);
                pollTimer = undefined;
            }
        }

        function cancelPoll() {
            cancelTimers();
            hideLoader();
            hideInfoMessage();
            hideQrCode();
            $scope.inProgress = false;
            $scope.pollInProgress = false;
            $scope.noClient = false;
        }

        function handleErrorResponse(status) {
            cancelPoll();
            displayPrefixedErrorMessage((status) ? status : 'ERROR');
        }

        function handleInvalidParameters() {
            cancelPoll();
            $log.info('Signing already collected');
        }

        function displayPrefixedErrorMessage(status) {
            displayErrorMessage(msgPrefix() + status);
        }

        function displayErrorMessage(status) {
            hideInfoMessage();
            displayMessage(status, $scope.errorMessageElementId);
            $scope.onFailed();
        }

        function hideErrorMessage() {
            $('#' + $scope.errorMessageElementId).hide();
        }

        function displayPrefixedInfoMessage(response) {
            displayInfoMessage(msgPrefix() + response.status);
        }

        function displayInfoMessage(status) {
            displayMessage(status, $scope.infoMessageElementId);
        }

        function hideInfoMessage() {
            $('#' + $scope.infoMessageElementId).hide();
        }

        function displayMessage(status, elementId) {
            var message = $filter('translate')('bankid_' + status);
            $('#' + elementId).show().text(message);
        }

        function displayQrCode(data) {
            QRCode.toDataURL(data).then(src => {
                $('#' + $scope.qrCodeElementId)
                    .show()
                    .find("img")
                    .attr("src", src);
            });
        }

        function hideQrCode() {
            $('#' + $scope.qrCodeElementId).hide();
        }

        function hideLoader() {
            $rootScope.$broadcast('event:hideLoader');
        }

        function msgPrefix() {
            return $scope.otherDevice ? 'mobile_' : '';
        }

        var statusHandlers = {
            'USER_SIGN': displayPrefixedInfoMessage,
            'COMPLETE': handleComplete,
            'OUTSTANDING_TRANSACTION': displayPrefixedInfoMessage,
            'NO_CLIENT': handleNoClient,
            'INVALID_PARAMETERS': handleInvalidParameters,
            'STARTED': displayPrefixedInfoMessage
        };
    })

    .directive('bankidSign', function () {
        return {
            controller: 'BankIdSignController',
            template:'<span><button class="btn btn-large btn-success" ng-disabled="disable()" ng-click="sign()">{{text}}</button> <a href="{{bankIdUrl}}" class="btn btn-large btn-info" ng-if="context === \'fondbyte\' && noClient" ng-disabled="disableLaunchBankID()" ng-class="{\'disabled\': disableLaunchBankID()}" ng-click="restartPoll()">{{\'fb_bankid_start\' | translate}}</a><iframe id="bankid-frame" src height="0" width="0" style="display: none" ng-if="context === \'fondbyte\' && !otherDevice"></iframe></span>',
            restrict: 'E',
            replace: true,
            scope: {
                text: '@',
                disabled: '&',
                infoMessageElementId: '@',
                errorMessageElementId: '@',
                qrCodeElementId: '@',
                signData: '&',
                context: '=',
                onStarted: '&',
                onFailed: '&',
                onComplete: '&',
                otherDevice: '=',
                openOnThis: '=',
                isApproved: '&',
                isTouchDevice:'@',
                fondbyte: '&'
            }
        };
});
