/**
 * Directive that inserts a div with optionto show the ajax loader. The
 * directive listen for broadcasted "showLoader" and "hideLoader" events.
 *
 * If a showLoader event is received and no ajax-loader icon is already
 * displayed, a ajax-loader is scheduled to show up in the future (after 500ms).
 *
 * If a hideLoader event is received all scheduled show ajax-loader are canceled
 * and the ajax-loader is hidden.
 */
angular.module('psUi', [])
    .directive('ajaxLoader', function() {
        return {
            controller: function($scope, $timeout) {
                var loaderPromises = [];
                var autoHide = $scope.autoHide !== "false";
                var ajaxUrlPattern = $scope.ajaxUrlPattern;
                var method = $scope.method;

                $scope.loaderClass = '';

                var loaderFunc = function() {
                    $scope.loaderClass = 'ajax-loader';
                };

                $scope.$on('event:showLoader', function(scope, data) {
                    if (methodMatches(data.method) && urlMatches(data.url)) {
                        if (loaderPromises.length === 0) {
                            loaderPromises.push($timeout(loaderFunc, 500));
                        }
                    }
                });

                $scope.$on('event:autoHideLoader', function (scope, data) {
                    if (autoHide) {
                        hideLoader();
                    }
                });

                $scope.$on('event:hideLoader', function(scope, data) {
                    hideLoader();
                });

                function methodMatches(requestMethod) {
                    return !method || requestMethod.toLowerCase() === method.toLowerCase();
                }

                function urlMatches(url) {
                    return !ajaxUrlPattern || url.match(ajaxUrlPattern);
                }

                function hideLoader() {
                    while (loaderPromises.length > 0) {
                        $timeout.cancel(loaderPromises.pop());
                    }
                    $scope.loaderClass = '';
                }

                $scope.show = function() {
                    return $scope.loaderClass.length > 0 ? true : false;
                };
            },

            scope: {
                autoHide: '@',
                ajaxUrlPattern: '@',
                method: '@'
            },

            restrict: 'E',
            replace: true,
            template: '<div ng-class="loaderClass" ng-show="show()">' +
                '<div class="loading" class="text-muted text-center">' +
                '<span><i class="fa fa-spinner fa-spin"></i></span>' +
                '</div>' +
                '</div>'
        };
    })

    /**
     * Http interceptor that keeps track of the number of outstanding ajax
     * request to the server side. As long as number of outstanding request
     * are more than zero a "showLoader" event is broadcasted for each new
     * ajax request.
     *
     * When all responses are received and the number of outstanding ajax
     * request are zero a "autoHideLoader" event is broadcasted"
     */

    .factory('ajaxLoaderInterceptor', function($q, $rootScope) {
        var count = 0;

        function isAjaxUrl(response) {
            return response && response.url && response.url.indexOf('/api/') >= 0;
        }

        function decreaseCount(response) {
            if (isAjaxUrl(response) && --count <= 0) {
                count = 0;
                $rootScope.$broadcast('event:autoHideLoader', count);
            }
        }

        function increaseCount(config) {
            if(isAjaxUrl(config) && ++count > 0) {
                $rootScope.$broadcast('event:showLoader', {url: config.url, method: config.method});
            }
        }

        return {
            request: function(config) {
                increaseCount(config);
                return config;
            },
            requestError: function(rejection) {
                decreaseCount(rejection);
                return $q.reject(rejection);
            },
            response: function(response) {
                decreaseCount(response.config);
                return response;
            },
            responseError: function(rejection) {
                decreaseCount(rejection.config);
                return $q.reject(rejection);
            }
        };
    })
    .config(function($httpProvider) {
        $httpProvider.interceptors.push('ajaxLoaderInterceptor');
    });
