angular.module('maxfonder').controller('FundPurchaseMaxfonderCtrl', function ($scope, $rootScope, $filter, $state, BankIdService, Message, MaxfonderInstrumentService, OrderService, ConfirmationService, PortfolioService, MaxfonderService) {

    $scope.RETAIL_PORTFOLIO_TYPE_CODE = 'MANUAL';
    $scope.VALID_PORTFOLIO_TYPE_CODES = ['MANUAL', 'ADVISORY'];

    $scope.order = OrderService.getOrCreateOrder();
    $scope.portfoliotypes = [];
    $scope.portfolios = [];
    $scope.selected = {portfolio: null, portfolioType: null};
    $scope.isTouchDevice = $rootScope.isTouchDevice;
    $scope.currentTab = 1;
    $scope.radioButtons = {signing: null, purchaseMode: 'retail'};
    $scope.checkboxes = {confirmFactsCheck: false, confirmIdentityCheck: false};
    $scope.isSigningStarted = false;
    $scope.submitted = false;
    $scope.allFunds = [];
    $scope.filtredFunds = [];
    $scope.portfolioSelected = isPortfolioTabSelected();
    $scope.searchFilter = "";
    $scope.currentPage = 1;
    $scope.numberOfPages = 0;
    $scope.fundsPerPage = 10;
    $scope.fundsPerPageOptions = [10, 20, 30];
    $scope.pageNrList = [];
    $scope.showLastPage = null;
    $scope.hasRegistration = OrderService.hasPendingSavedRegistration();

    $scope.debitMonthDayList = createDebitMonthDayList();

    var translate = $filter('translate');

    //Registration
    if ($scope.hasRegistration) {
        $scope.registration = OrderService.getSavedRegistration();
    } else {
        $scope.deposits = OrderService.getDeposits();
    }

    // populate dropdown choices for monthlychargingday
    function createDebitMonthDayList() {
        let arr = [];
        for(var day=1; day <= 28; day++){
            arr.push(day);
        }
        return arr;
    }

    //Tab functions
    $scope.setMainTab = function (index) {
        $scope.currentTab = index;
    };

    function isPortfolioTabSelected() {
        return $scope.currentTab === 2;
    }

    // Portfolio types
    MaxfonderService.getPortfolioTypes().$promise.then(function (types) {
        types = _.sortBy(types, function(type) { return type.portfolioTypeId; });
        $scope.portfoliotypes = types.filter((type) => $scope.VALID_PORTFOLIO_TYPE_CODES.includes(type.instIntCode));
    });

    // Funds
    $scope.instrumentsPromise = MaxfonderInstrumentService.getInstrumentsForUnivers().$promise;

    $scope.instrumentsPromise.then(function (instruments) {
        if (_.isEmpty(instruments)) {
            $scope.couldNotLoadInstruments = true;
            var errorMessage = translate('fb_error_could_not_load_instruments') + ' ' + translate('ps_contact_support');
            Message.error('maxfonder_fundPurchase_instrumentLoadErrTitle', errorMessage, 'could_not_load_instrument_error');
        } else {
            Message.resolveMessage('could_not_load_instrument_error');
            var allFundsTemp = instruments;
            allFundsTemp.forEach(function (fund) {
                fund.monthlyAmount = null;
                fund.initialAmount = null;
                fund.isinCurrencyId = fund.isin + fund.currency;
                if (!fund.annualFee) {
                    fund.annualFee = "";
                }
            });

            var groups = _.groupBy(allFundsTemp, function (f) {
                return f.isin;
            });
            _.each(groups, function (group) {
                if (group.length > 1) {
                    group.forEach(function (f) {
                        f.isin = f.isin + '   ' + f.currency;
                    });
                }
            });

            $scope.allFunds = allFundsTemp;

            $scope.numberOfPages = Math.ceil($scope.allFunds.length / $scope.fundsPerPage);
            $scope.changePage($scope.currentPage);
        }
    });

    $scope.hasNonDailyFunds = function (funds) {
        return funds.filter(isNonDailyFund).length > 0;
    };

    function isNonDailyFund(fund) {
        return (angular.isDefined(fund) && fund !== null) && fund.pricingFrequency !== 'DAILY';
    }


    //Manipulate selected funds
    $scope.addSelectedFund = function (isinCurrencyId) {
        $scope.order.addSelectedFund($scope.allFunds.find(function (f) {
            return f.isinCurrencyId === isinCurrencyId;
        }));
    };


    //Pagination
    $scope.changePage = function (pageNr) {
        //Saves scroll position
        var scrollY = window.pageYOffset;
        var scrollX = window.pageXOffset;
        $scope.currentPage = pageNr;
        $scope.setPageNrList(pageNr);

        //Scrolls back after ng-repeat has finished filling the table
        //necessary to prevent automatic scrolling to the bottom of the page
        window.setTimeout(function () {
            window.scrollTo(scrollX, scrollY);
        }, 1);
    };

    $scope.setPageNrList = function (pageNr) {
        $scope.pageNrList = [];
        var numberOfButtons = 5;

        var startIndex = pageNr - Math.floor(numberOfButtons / 2);
        var endIndex = pageNr + Math.ceil(numberOfButtons / 2);
        if ($scope.numberOfPages < numberOfButtons) {
            startIndex = 1;
            endIndex = $scope.numberOfPages + 1;
        }
        else if (pageNr < Math.ceil(numberOfButtons / 2)) {
            startIndex = 1;
            endIndex = numberOfButtons + 1;
        }
        else if (pageNr > $scope.numberOfPages - 2) {
            startIndex = ($scope.numberOfPages - numberOfButtons) + 1;
            endIndex = $scope.numberOfPages + 1;
        }

        for (var i = startIndex; i < endIndex; i++) {
            $scope.pageNrList.push(i);
        }

        $scope.showLastPage = !_.contains($scope.pageNrList, $scope.numberOfPages) && $scope.numberOfPages !== 0;
    };

    function sendError(title, message) {
        $scope.isSigningStarted = false;
        $scope.submitted = false;
        Message.error(title, message, 'sendErr');
    }

    //Signing
    $scope.signingStarted = function () {
        $scope.isSigningStarted = true;
        $scope.order.generateFundPurchases($scope.portfolioSelected);
        Message.resolveMessage('sendErr');
        Message.resolveMessage('dataValidationErr');
    };

    $scope.signingFailed = function () {
        sendError('maxfonder_fundPurchase_errTitle', 'maxfonder_fundPurchase_send_err');
    };

    //Send Order
    function submittingOrder() {
        $scope.submitted = true;
        $scope.order.submitted = true;
        ConfirmationService.reset();
    }

    $scope.send = function (signature, signatureVisibleData, signatureToken, ipAddress, bankIdLogEntryId) {
        if ($scope.isReady()) {
            submittingOrder();
            OrderService.send($scope.order, signature, signatureVisibleData, signatureToken, ipAddress, bankIdLogEntryId).then(
                function (documentId) {
                    $scope.order.pdfId = documentId;
                    OrderService.saveOrder($scope.order);
                    $state.go('maxfonderBekraftelse');
                }, function () {
                    sendError('maxfonder_fundPurchase_errTitle', 'maxfonder_fundPurchase_send_err');
                });
        }
    };

    $scope.generatePDFWithoutSendingOrder = function () {
        if ($scope.isReady()) {
            submittingOrder();
            Message.resolveMessage('dataValidationErr');
            Message.resolveMessage('sendErr');
            $scope.order.generateFundPurchases($scope.portfolioSelected);
            OrderService.generatePDFWithoutSendingOrder($scope.order).then(
                function (pdfResult) {
                    $scope.order.pdfResult = pdfResult;
                    OrderService.saveOrder($scope.order);
                    $state.go('maxfonderBekraftelse');
                }, function () {
                    sendError('maxfonder_fundPurchase_errPDFTitle', 'maxfonder_fundPurchase_generatePDF_err');
                }
            );
        }
    };

    $scope.abort = function () {
        OrderService.abort();
        $state.go('maxfonder');
    };

    //Validation
    $scope.showError = function () {
        if (!$scope.hasDepositNumber()) {
            Message.error('maxfonder_fundPurchase_errTitle', 'maxfonder_fundPurchase_depotNumber_err_message', 'noDepotNumber');
            return;
        }

        Message.resolveMessage('dataValidationErr');
        var validationError = $scope.order.dataValidation($scope.portfolioSelected, $scope.selected.portfolioType, $scope.selected.portfolio);
        if (validationError !== undefined) {
            Message.error(validationError.title, validationError.message, 'dataValidationErr');
        }
        else if (!$scope.checkboxes.confirmFactsCheck) {
            Message.error('maxfonder_fundPurchase_errTitle', 'maxfonder_fundPurchase_isConfirmed_err_message', 'isConfirmedErr');
        } else if (!$scope.signingSelected()) {
            Message.error('maxfonder_fundPurchase_errTitle', 'maxfonder_fundPurchase_signingSelected_err_message', 'signingSelectedErr');
        }
    };

    $scope.isReady = function () {
        return $scope.signingSelected() &&
            $scope.hasDepositNumber() &&
            $scope.checkboxes.confirmFactsCheck &&
            (!$scope.hasRegistration || $scope.checkboxes.confirmIdentityCheck) &&
            $scope.order.dataValidation($scope.portfolioSelected, $scope.selected.portfolioType, $scope.selected.portfolio) === undefined;
    };

    $scope.signingSelected = function () {
        return $scope.radioButtons.signing;
    };

    $scope.hasDepositNumber = function () {
        return $scope.hasRegistration || $scope.order.depositNumber;
    };

    //Error watchers
    $scope.$watch('radioButtons.signing', function () {
        $scope.order.eSigningSelected = $scope.radioButtons.signing !== 'print';
        Message.resolveMessage('signingSelectedErr');
    });
    $scope.$watch('order.depositNumber', function () {
        if ($scope.order.depositNumber) {
            Message.resolveMessage('noDepotNumber');
        }
    });
    $scope.$watch('checkboxes.confirmFactsCheck', function () {
        if ($scope.checkboxes.confirmFactsCheck) {
            Message.resolveMessage('isConfirmedErr');
        }
    });

    $scope.$watch('currentTab', function () {
        $scope.portfolioSelected = isPortfolioTabSelected();
        Message.resolveMessage('dataValidationErr');
        if ( !$scope.portfolioSelected ) {
            $scope.clearPortfolioData();
        } else {
            $scope.initPortfolioData();
        }
    });

    // mode watcher
    $scope.$watch('radioButtons.purchaseMode', function () {
        $scope.clearPortfolioData();
        // reset active tab and clear data on switch
        if ( $scope.radioButtons.purchaseMode === 'privateBanking' ) {
            $scope.setMainTab(2);
            $scope.portfolios = [];
        }
        if ( $scope.radioButtons.purchaseMode === 'retail' && isPortfolioTabSelected() ) {
            $scope.initPortfolioData();
        }
    });

    $scope.$watch('selected.portfolioType', function () {
        if ( $scope.selected.portfolioType != null ) {
            MaxfonderService.getPortfoliosOfType($scope.selected.portfolioType.portfolioTypeId).$promise.then(function (portfolios) {
                $scope.portfolios = portfolios;
            });
        }
    });

    $scope.$watch('selected.portfolio', function () {
        if ( $scope.selected.portfolio != null ) {
            $scope.order.portfolio.portfolioId = $scope.selected.portfolio.portfolioId;
            $scope.order.portfolio.portfolioName = $scope.selected.portfolio.name;
        }
        if ( $scope.selected.portfolioType != null ) {
            $scope.order.portfolio.portfolioTypeId = $scope.selected.portfolioType.portfolioTypeId;
            $scope.order.portfolio.portfolioTypeName = $scope.selected.portfolioType.name;
            $scope.order.portfolio.portfolioTypeInstIntCode = $scope.selected.portfolioType.instIntCode;
        }
    });

    $scope.clearPortfolioData = function() {
        $scope.selected.portfolioType = null;
        $scope.selected.portfolio = null;
        $scope.order.portfolio = {};
    };

    $scope.initPortfolioData = function() {
        if ($scope.radioButtons.purchaseMode === 'retail') {
            $scope.selected.portfolioType = $scope.getPortfolioTypeByInstIntCode($scope.RETAIL_PORTFOLIO_TYPE_CODE);
            MaxfonderService.getPortfoliosOfType($scope.selected.portfolioType.portfolioTypeId).$promise.then(function (portfolios) {
                $scope.portfolios = portfolios;
            });
        }
    };

    $scope.getPortfolioTypeByInstIntCode = function(code) {
        return $scope.portfoliotypes.find((type) => type.instIntCode === code);
    };

    //Pagination watcher
    $scope.$watchGroup(['searchFilter', 'sortBy', 'sortRevert', 'fundsPerPage'], function () {
        $scope.numberOfPages = Math.ceil($scope.filtredFunds.length / $scope.fundsPerPage);
        $scope.changePage(1);
    });

})
.filter('nameAndIsinFilter', function () {
    //Filter that filter on name and isin based on searchFilter
    return function (funds, searchFilter) {
        var filtered = [];
        var subString = searchFilter.toLowerCase();
        angular.forEach(funds, function (fund) {
            if ((fund.name && fund.name.toLowerCase().search(subString) !== -1) ||
                (fund.isin && fund.isin.toLowerCase().search(subString) !== -1)) {
                filtered.push(fund);
            }
        });
        return filtered;
    };
});

