
angular.module('psModule').controller('momentModalDetailsCtrl',
    ($scope, instrumentByIsinService, $uibModalInstance, fbelopp, $sce) => {
        $scope.asHtml = $sce.trustAsHtml;
        $scope.fbelopp = fbelopp;

        $scope.getFund = (isin) => {
            return instrumentByIsinService.getInstrument(isin);
        };

        const removeEventListener = $scope.$on("$locationChangeStart", () => {
            removeEventListener();
            $uibModalInstance.dismiss();
        });
    });
