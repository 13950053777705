angular.module('psModule').factory('benefitService', function ($q, $resource, $rootScope, $translate, $location) {
    var bs = $location.search()['useBS'];
    var cache = $location.search()['ignoreCache'];
    var fbeskedResource = $resource('/ps/api/benefits/fbesked', {
        lang: $translate.use(),
        useBS: bs,
        ignoreCache: cache
    }, {});

    const benefitSummaryRequestWrapperResource = $resource('/ps/api/benefits/benefit-summary-request-wrapper', {}, {});

    var fbeskedFuture = $q.defer();
    var fbeskedPromise = fbeskedFuture.promise;

    if ($rootScope.user.fullAccess) {
        fbeskedResource.get().$promise
            .then(function (data) {
                fbeskedFuture.resolve(data);
            }, function (error) {
                fbeskedFuture.reject(error);
            });
    }

    var benefitService = {};

    benefitService.getFbesked = function () {
        return fbeskedPromise;
    };

    benefitService.getBenefitSummaryRequestWrapper = () => benefitSummaryRequestWrapperResource.get().$promise;

    benefitService.getSubCategory = function (category, subcat) {
        return fbeskedPromise.then(function (fbesked) {
            return fbesked.categoryMap[category].subCategoryMap[subcat];
        });
    };

    benefitService.calculateTotalCompensation = function (ersattningar) {
        return _.reduce(ersattningar, function (memo, fbelopp) {
            if (!angular.isDefined(fbelopp.ersattning.belopp) || !angular.isDefined(fbelopp.ersattning.belopp.value) || !fbelopp.ersattning.includeInSum) {
                return memo;
            }
            return memo + fbelopp.ersattning.belopp.value;
        }, 0);
    };

    var visibilityHides = ['NONE', 'HEADER_ONLY'];
    var visibilityValueHides =  visibilityHides.concat(['HEADER_AND_LONGTEXT']);

    benefitService.hideErsattningar = function(ersattning) {
        return $rootScope.checkDefined(ersattning, 'visibility.PrivateServices') &&
            ersattning.visibility.PrivateServices === visibilityHides[0];
    };

    benefitService.hideDesc = function(ers) {
        return $rootScope.checkDefined(ers, 'visibility.PrivateServices') &&
            _.contains(visibilityHides, ers.visibility.PrivateServices);
    };

    benefitService.hideValue = function(ers) {
        return $rootScope.checkDefined(ers, 'visibility.PrivateServices') &&
            _.contains(visibilityValueHides, ers.visibility.PrivateServices);
    };

    return benefitService;

});
