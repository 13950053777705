/**
 * Created by sofia.nilsson on 2017-07-03.
 */
angular.module('psModule').factory('identificationService', function ($resource, BankIdService, $timeout, $interval) {
    var identificationService = {},
        pollSleepMillis = 6000,
        pollIntervalMillis = 3000,
        pollTimeout,
        pollInterval;

    identificationService.cancelPoll = function () {
        $timeout.cancel(pollTimeout);
        $interval.cancel(pollInterval);
        pollTimeout = undefined;
        pollInterval = undefined;
    };

    function startPoll(callback, errorCallback) {
        pollTimeout = $timeout(function () {
            pollInterval = $interval(function () {
                var identResponse = BankIdService.identCollect(identificationService.orderResponse);
                identResponse.$promise.then(function (response) {
                    callback(response);
                }, function (response) {
                    errorCallback(response.data);
                });

            }, pollIntervalMillis);
        }, pollSleepMillis);
    }

    identificationService.identBid = function (callback, errorCallback) {
        if (isPending()) {
            return;
        }
        var loginBidRequest = BankIdService.ident();
        loginBidRequest.$promise.then(function (response) {
            if (response.isError) {
                errorCallback(response);
                return;
            }
            identificationService.orderResponse = response.payload;
            startPoll(callback, errorCallback);
        }, function (response) {
            errorCallback(response.data);
        });
    };

    function isPending() {
        return pollTimeout || pollInterval;
    }

    return identificationService;
});
