angular.module('psModule').directive('psSecureLink', function(TokenService) {
    return {
        restrict: 'E',
        template: '<a class="{{cssClass}}" ng-href="{{ps.url}}" target="{{target}}" ng-transclude role="{{type}}"></a>',
        transclude: true,
        scope: {
            url: "@",
            target: '@',
            param: '@',
            cssClass: '@',
            type: '@',
            paramValue: '='
        },
        link: function(scope, element, attributes) {
            scope.ps = {};
            attributes.$observe('url', function(url) {
                var targetUrl = TokenService.attachPsTokenToUrl(url);
                if (scope.param && scope.paramValue) {
                    targetUrl += '&' + scope.param + '=' + scope.paramValue;
                }

                scope.ps.url = targetUrl;
            });
        }
    };
});
