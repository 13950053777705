angular.module('psModule').controller('PremiumsCtrl', function($scope, premiumService, Message, DateService) {

    $scope.dataLoaded = false;
    premiumService.getPremiums(function (premiums) {
        $scope.premiumsPayedByInsured = _.filter(premiums, function (premium) {
            return premium.benefitType === 'PRIVATE';
        });

        $scope.premiumsPayedByEmployer = _.filter(premiums, function (premium) {
            return premium.benefitType !== 'PRIVATE';
        });

        var listOfDates = _.map(premiums, function (premium) {
            return premium.updated;
        });
        $scope.latestUpdate = DateService.getLatestDate(listOfDates);

        $scope.dataLoaded = true;
    });

});
