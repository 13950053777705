angular.module('psModule').filter('percentage', function($filter) {
	return function(input, fraction, showZero) {

        function formatPercentage(percentage) {
            var numberFilter = $filter('number');
            return numberFilter(percentage, fraction) + ' %';
        }

        if(input) {
            return formatPercentage(+input);
        } else if (showZero === true) {
            return formatPercentage(0);
        } else {
            return '-';
        }
	};
});
