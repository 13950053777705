angular.module('psModule').directive('psRiskBar', function() {
    return {
        restrict: 'E',
        scope: {
            risk: '='
        },
        template:'<div class="risk-group" role="group"><span class="risk-value" ng-class="{\'active\': risk === value}" ng-repeat="value in riskOptions">{{value}}</span></div>',
        link: function(scope, element, attrs, fn) {
            scope.riskOptions = [1, 2, 3, 4, 5, 6, 7];
        }

    };
});
