angular.module('psModule')
    .factory('faktainsamlingResource',
        function ($resource) {
            return $resource('/ps/api/faktainsamling/:section', {}, {
                'getBasicInformation': {method: 'GET', params: {}, isArray: false}
            });
        }
    )
    .service('faktainsamlingService',
        function ($resource, $q) {
            var service = {};

            var fiResource = $resource('/ps/api/faktainsamling', {}, {});
            var fiFuture = $q.defer();
            var fiPromise = fiFuture.promise;
            fiResource.get(function (data) {
                fiFuture.resolve(data);
            });

            service.getBasicInformation = function () {
                return fiPromise;
            };

            return service;
        }
    )
;
