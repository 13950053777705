angular.module('psModule')
    .config(function($sceProvider) {
        $sceProvider.enabled(false);
    })
    .directive('psFundDetailsLink', function($q, $state, $uibModal, $rootScope, $filter, $sce, StorageService) {

        var translate = $filter('translate');

        var linker = function($scope, element, attrs) {
            $scope.isTouch = $rootScope.isTouchDevice;

            $scope.getTooltipText = function() {
                if ($scope.isTouch) {
                    return null;
                }

                if ($scope.tooltipOnlyForLinks && !$scope.asLink) {
                    return null;
                }

                if ($scope.tooltipContent) {
                    return $sce.trustAsHtml($scope.tooltipContent);
                }

                if ($scope.containerList && typeof $scope.containerList.length !== 'undefined') {
                    var containerNames = "";
                    for (var i = 0; i < $scope.containerList.length; i++) {
                        containerNames = containerNames + "- " + $filter('translate')($scope.containerList[i]);
                        if (i < $scope.containerList.length - 1) {
                            containerNames = containerNames + "<br>";
                        }
                    }
                    if (containerNames.length) {
                        return $sce.trustAsHtml(translate('ps_fund_belongs_to_containers') + "<br>" + containerNames);
                    }
                }

                return null;
            };

            $q.when($scope.fundMeta).then(function(fund) {
                $scope.fund = fund;
                if ($scope.fund) {
                    $scope.name = $scope.fund.name ? $scope.fund.name : $scope.name;
                }

                $scope.asLink = $scope.fund && $scope.fund.securityId && $scope.fund.universeId;
            });

            $scope.authToken = StorageService.getPsToken();

            $scope.openDetails = function() {
                $uibModal.open({
                    template:'<div><div class="modal-header mm-modal-header"><p class="text-right mm-modal-close" ng-click="$dismiss()"><i class="fa fa-times"></i></p></div><div class="modal-body"><iframe ng-if="!useSecurities" id="detailsFrame" ng-src="{{detailsUrl}}" style="zoom:0.60" width="99.6%" height="1500" frameborder="0"></iframe><mm-fund-analysis-ce ng-if="useSecurities" ng-prop-isin="fund.isin" ng-prop-currency="fund.currency"><div class="col-md-12"><div class="alert alert-danger" translate="ps_fundanalysis_securities_not_available"></div></div></mm-fund-analysis-ce></div><div class="modal-footer"><button class="btn btn-default" ng-click="$dismiss()">{{\'ps_close\' | translate}}</button></div></div>',
                    controller: 'FunddetailsmodalCtrl',
                    size: 'lg',
                    resolve: {
                        fund: function() { return $scope.fund; }
                    }
                });
            };

            $scope.useSecurities = useSecurities();

            function useSecurities() {
                return $scope.$root.config && $scope.$root.config.toggles && $scope.$root.config.toggles.USE_SECURITIES === true;
            }

        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                fundMeta: '=fund',
                name: '=',
                containerList: '=',
                tooltipContent: '@',
                tooltipOnlyForLinks: '='
            },
            template:'<span uib-tooltip-html="getTooltipText()" tooltip-placement="right"><a ng-if="asLink && !isTouch && !useSecurities" ui-sref="funddetails({sid: fund.securityId, uid: fund.universeId, auth: authToken})" target="_blank" ng-bind="fund.name"></a> <a ng-if="asLink && (isTouch || useSecurities)" class="ps-fund-link" ng-click="openDetails()" target="_blank" ng-bind="fund.name"></a> <span ng-if="!asLink" ng-bind="name"></span></span>',
            link: linker
        };
    });
