angular.module('psModule')
    .controller('PortfolioanalysisCtrl', function ($scope, $transition$, $sce, $rootScope) {
        const useSecurities = $scope.$root.config && $scope.$root.config.toggles && $scope.$root.config.toggles.USE_SECURITIES === true;
        const values = $transition$.params().values;
        const isinCodes = $transition$.params().isinCodes;
        $scope.authorized = function () {
            return useSecurities ? $scope.user.fullAccess : $scope.user.broker;
        };
        $scope.analysisInfo = {
            funds: {}
        };
        if (isinCodes && values) {
            if (useSecurities) {

                const isinCodesList = isinCodes.split("|");
                const valuesList = values.split("|");
                $scope.holdings = isinCodesList
                    .map((isin, index) => ({isin, type: "HOLDING_CAPITAL", value: valuesList[index]}));
            } else {

                if (!$scope.authorized()) {
                    $scope.analysisInfo.funds.isinCodes = isinCodes;
                    $scope.analysisInfo.funds.values = values;
                    const urlTemplate = _.template($rootScope.config.xray.xrayUrlTemplate);
                    $scope.analysisInfo.url = $sce.trustAsResourceUrl(urlTemplate($scope.analysisInfo.funds));
                }
            }
        } else {
            $scope.analysisInfo.missing = true;
        }

        $scope.useSecurities = $scope.$root.config && $scope.$root.config.toggles && $scope.$root.config.toggles.USE_SECURITIES === true;

    });
