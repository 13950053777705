angular.module('psModule')
    .controller('MaxfonderCtrl', function ($scope, $document, $state, assetService, MaxfonderService, Message) {
        $scope.maxf = { active: false };
        $scope.isValidUserAddress = true;
        $scope.features = {};

        $scope.diskEnabled = false;
        $scope.kycCheckEnabled = false;

        $scope.kycChecked = false;
        $scope.kycValid = false;

        MaxfonderService.isActive().$promise.then(function (response) {
            if (response.isActive) {
                $scope.maxf.active = true;
                $scope.maxf.href = '/ps/api/maxfonder/login';
            }
        });

        $scope.goToIfActive = function (state) {
            Message.resolveMessage('goToErr');
            MaxfonderService.isValid().$promise.then(function (response) {
                    $scope.maxf.active = response.isValid;
                    if ($scope.maxf.active) {
                        $state.go(state);
                    } else {
                        Message.error('maxfonder_fundPurchase_errTitle', 'maxfonder_start_goToIfActiveErr', 'goToErr');
                    }
                }
            );
        };

        $scope.isEmpty = function(str){
            return (!str || 0 === str.length);
        };

        $scope.validateUserAddress = function(user){
            if($scope.isEmpty(user.address) || $scope.isEmpty(user.zip) || $scope.isEmpty(user.city)){
                return false;
            }
            return true;
        };

        $scope.checkKyc = function() {
            if ($scope.kycCheckEnabled) {
                console.log("checking kyc...");
                MaxfonderService.hasValidKyc().$promise.then(function (response) {
                    $scope.kycChecked = true;
                    $scope.kycValid = response.isValid;
                });
            } else {
                $scope.kycChecked = true;
                $scope.kycValid = true;
            }
        };

        $scope.$on('$viewContentLoaded', function() {
            MaxfonderService.features().$promise.then(function (response) {
                if (response) {
                    console.log(response);
                    $scope.features = response;
                    $scope.diskEnabled = response.ENABLE_MAXFONDER_DISK;
                    $scope.kycCheckEnabled = response.ENABLE_MAXFONDER_KYC_CHECK;
                }
                $scope.checkKyc();
            });
            $scope.isValidUserAddress = $scope.validateUserAddress($scope.user);
        });
    });

