angular.module('psModule')
    .controller('ForhandsinfoCtrl', function($scope, $http, Message, $translate, $rootScope, $log) {

        $translate('ps_support_mail').then(function(supportEmail) {
            $scope.contact = {
                name: $rootScope.user.name,
                from: $rootScope.user.email,
                to: supportEmail
            };
        });

        $scope.sendForhandsinfoMail = function() {
            $http.post('/ps/api/user/forhandsinfomail', $scope.contact)
                .then(() => {
                    $translate('ps_mail_sent_to', {
                    to: $scope.contact.to
                }).then(function(successMess) {
                    $scope.contact.message = '';
                    Message.success('ps_mail_sent', successMess, 'contact');
                    $scope.contactForm.$setPristine();
                }, (error) => $log.error('error:', error));
             });
        };
    });
