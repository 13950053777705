angular.module('psModule').directive('fiEconomySummarySection', function() {
    return {
        restrict: 'E',
        template:'<div class="well info"><div class="mm-panel table-responsive"><table class="table table-striped" ng-class="behavior && behavior.value ? \'text-muted\':\'\'"><thead><tr><th mm-tablesorter by="sortBy" reverse="sortRevert" order="\'description\'" class="sortable" ng-class="{sortedBy: sortBy==\'description\'}">{{header | translate}}</th><th mm-tablesorter by="sortBy" reverse="sortRevert" order="\'amount\'" class="text-right sortable" ng-class="{sortedBy: sortBy==\'amount\'}">{{unit | translate}}</th></tr></thead><tbody><tr ng-repeat="value in values | orderBy: sortBy:sortRevert"><td ng-class="{expired: !!value.expired}">{{value.description}}</td><td class="text-right no-line-break" ng-class="{expired: !!value.expired}">{{value.amount | number:0}}</td></tr><tr ng-if="!values || values.length === 0"><td colspan="2" translate="fi_economy_summary_empty"></td></tr></tbody><tfoot ng-if="!!values && values.length > 0"><tr><td colspan="2" class="no-line-break text-right"><strong><span translate="ps_sum"></span>: {{sum | number:0}}</strong></td></tr></tfoot></table><form ng-if="behavior"><input type="checkbox" ng-model="behavior.value" ng-change="behavior.toggle(behavior.value)" ng-checked="behavior.value" name="behavior_checkbox" value="hide"> {{behavior.text}}</form></div></div>',
        transclude: true,
        scope: {
            values: '=',
            sum: '=',
            header: '=',
            unit: '=',
            behavior: '=?'
        },
        controller: function ($scope) {
            $scope.sortBy = 'description';
            $scope.sortRevert = false;
        }
    };
});
