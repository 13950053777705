angular.module('psModule')
    .controller('SimpleLoginCtrl', function($scope, $http, $window, $state, $rootScope, LoginService) {
        $scope.md = {};
        $scope.simple = function() {
            $scope.md.error = '';
            var prom = $http.post('/ps/api/auth/simple', {
                username: $scope.md.pnr
            });
            prom.then(function(response) {
                LoginService.login(response.data);
            }, function(error) {
                var message = error.data ? error.data.error : error.statusText;
                $scope.md.error = {
                    status: error.status,
                    message: message
                };

            });
        };
    });
