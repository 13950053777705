angular.module('psModule').directive('psFundchangeButton', function ($state) {
	return {
		restrict: 'E',
        scope: {
            insurance: '='
        },
        template: '<button ng-disabled="disable()" class="btn btn-success" ng-click="goToModifiera()">{{"fb_change_funds" | translate}}</button>',
		link: function (scope, element, attrs, fn) {
            scope.disable = function() {
                return !scope.insurance.metaData.isChangeable;
            };

            scope.goToModifiera = function () {
                $state.go('fondbyteModifiera', {policyNumber: scope.insurance.forsakringsNummer});
            };
		}
	};
});
