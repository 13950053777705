angular.module('fondbyte')
    .controller('OverviewCtrl', function ($scope, $filter, ChangeService, fundChangeableService, activeFundChangeService, Message, instrumentService, DateService, $q, $http, $uibModal, $translate, $rootScope) {

    var activeOrdersFuture = $q.defer();
    $scope.activeOrders = activeOrdersFuture.promise;
    $scope.infodate = "2015-01-01";
    $scope.applyLimit = true;
    $scope.electronicFundChangeOpen = true; // Start with true to prevent flashing of information box on loading.

    var resultLimit = 10;
    var showAll = false;

    var init = function() {
        ChangeService.isElectronicFundChangeOpen().then(function (result) {
            $scope.electronicFundChangeOpen = result;
        });

        fundChangeableService.getFundChangeableInsurances().then(function (insurances) {
            var companies = _.uniq(_.pluck(insurances, 'bolag'));

            var rowCount = 0;
            var row = [];
            var rows = [];
            _.each(companies, function (bolag) {
                var insurancesByCompany = _.where(insurances, {
                    bolag: bolag
                });
                row.push(insurancesByCompany);
                if (rowCount === 0) {
                    rows.push(row);
                }
                if (++rowCount === 2) {
                    row = [];
                    rowCount = 0;
                }
            });
            $scope.insurancesLoaded = true;

            $scope.sortedData = rows;
        })
    };

    // Initialize controller stuff
    init();

    function applyLimit(list) {
        return $scope.applyLimit ? $filter('limitTo')(list, resultLimit) : list;
    }

    $scope.filterHistory = function () {
        if (showAll) {
            $scope.filteredHistoricOrders = $scope.historicOrders;
        } else {
            $scope.filteredHistoricOrders = applyLimit($scope.historicOrders);
        }
    };

    $scope.toggleLimit = function () {
        $scope.applyLimit = !$scope.applyLimit;
        $scope.filterHistory();
    };

    $scope.showLessBtn = function () {
        return !$scope.applyLimit && $scope.filteredHistoricOrders.length === $scope.historicOrders.length;
    };

    $scope.resultIsLimited = function () {
        return $scope.filteredHistoricOrders.length === resultLimit;
    };

    ChangeService.getAllOrders().then(function (all) {
        $scope.ordersLoaded = true;

        if (_.isEmpty(all)) {
            return;
        }

        activeOrdersFuture.resolve(_.filter(all, function(order) {
                                        return order.active;
                                    }));
        $scope.historicOrders = addCustomInfo(all);
        $scope.filterHistory();
    });

    function addCustomInfo(orderList) {
        var arrayLength = orderList.length;
        for (var i = 0; i < arrayLength; i++) {
            var fundOrder = orderList[i];
            fundOrder.oldSparplan.innehav = filterKontantInnehavAndOresavrundning(fundOrder.oldSparplan.innehav);
        }
        return orderList;
    }

    function filterKontantInnehavAndOresavrundning(sparfordelning) {
           return _.reject(sparfordelning, function(item) {
               if(!item.innehav) {
                   return false;
               }
               return item.innehav.type === 'Kontanter' && 0 === item.shareOfCapital && 0 === item.shareOfPremium;
           });
    }

    $scope.getCompany = function (data) {
        return _.uniq(_.pluck(data, 'bolag'))[0];
    };

    $scope.getLatestDate = function (insurances) {
        return DateService.getLatestDate(_.map(insurances, function (insurance) {
            return insurance.sparplan.valueDate;
        }));
    };

    $scope.dataLoaded = function () {
        return $scope.ordersLoaded && $scope.insurancesLoaded;
    };

    $scope.hasNoChangeable = function() {
        return $scope.insurancesLoaded && _.isEmpty($scope.sortedData);
    };

    $scope.isElectronicFundChangeOpen = function() {
        return !!$scope.electronicFundChangeOpen;
    };

    $scope.isSentElectronic = function (order) {
        return order.type !== 'Paper' && order.state !== 'New';
    };
});
