angular.module('psModule').factory('groupBenefitService', function($q, $resource) {

    var gruppForsakringResource = $resource('/ps/api/benefits/gruppforsakring', {}, {});

    var wisenticResource = $resource('/ps/api/benefits/wisentic', {}, {"getWisenticLink": {
        method: "get",
            isArray: false
        }});

    var gruppForsakringFuture = $q.defer();
    var gruppForsakringPromise = gruppForsakringFuture.promise;

    var wisenticLink = wisenticResource.getWisenticLink().$promise;

    gruppForsakringResource.query().$promise.then(function (data) {
        onLoad(data);
        gruppForsakringFuture.resolve(data);
    });

    var groupBenefitService = {};

    groupBenefitService.getGruppforsakring = function () {
        return gruppForsakringPromise;
    };

    groupBenefitService.getWisenticLink = function () {
        return wisenticLink;
    };

    function onLoad(gruppForsakringar) {
        _.each(gruppForsakringar, function (gforsakring) {
            _.each(gforsakring.dokument, function (dokument) {
                var nmn = dokument.namn.split("\\");
                dokument.kortnamn = nmn[nmn.length - 1];
            });
        });
    }

	return groupBenefitService;

});
