angular.module('fondbyte').factory('instrumentService', function($resource, $cacheFactory, $q) {

    var service = {};
    var instrumentCache = $cacheFactory('instrumentCache');

    service.instrumentsResource = $resource('/ps/api/instruments', {}, {});

    function prependZero(number) {
        return ('0' + number).slice(-2);
    }

    function formatDateArray(arr) { // Format date array [2017,3,31] as "2017-03-31"
        if (arr && 3 === arr.length) {
            return arr[0] + '-' + prependZero(arr[1]) + '-' + prependZero(arr[2]);
        }
        return '';
    }

    function createCacheKey(instrKey) {
        // Replaces undefined or null values as empty string
        return [instrKey.orgNo, instrKey.businessType, instrKey.compensationModel, instrKey.exclusiveRange,
            instrKey.universeIdentifier, formatDateArray(instrKey.datering)
        ].join('_');
    }

    service.getInstrumentsForUnivers = function(instrKey) {
        var cacheKey = createCacheKey(instrKey);
        var instruments = instrumentCache.get(cacheKey);

        if (!instruments) {
            instruments = service.instrumentsResource.query({
                orgNo: instrKey.orgNo,
                compensationModel: instrKey.compensationModel,
                businessType: instrKey.businessType,
                chargeModel: instrKey.chargeModel,
                exclusiveRange: instrKey.exclusiveRange,
                universeIdentifier: instrKey.universeIdentifier,
                insuranceDate: formatDateArray(instrKey.datering)
            });
            instrumentCache.put(cacheKey, instruments);
        }
        return instruments;
    };

    service.getInstrument = function(instrKey, isin){
        if (_.isEmpty(instrKey.orgNo) || _.isEmpty(isin)){
            return null;
        }
        var cacheKey = createCacheKey(instrKey) + '_' + isin;
        var promise = instrumentCache.get(cacheKey);

        if (!promise) {
            promise = $q(function(resolve, reject) {
                service.getInstrumentsForUnivers(instrKey).$promise.then(function(instruments) {
                    if (_.isEmpty(instruments)) {
                        resolve(null);
                    } else {
                        var instrument = _.findWhere(instruments, {
                            isin: isin
                        });
                        resolve(instrument);
                    }
                }, reject);
            });
            instrumentCache.put(cacheKey, promise);
        }
        return promise;
    };

    return service;
});
