angular.module('psModule').controller('SpinnerModalCtrl', ['$scope', '$modalInstance','header', 'buttonText',function($scope, $modalInstance,header, buttonText){

    $scope.header = header;
    $scope.buttonText = buttonText;

    $scope.buttonClicked = function() {
        $modalInstance.close(true);
    };

}]);
