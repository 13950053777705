angular.module('fondbyte')
    .service('ChangeService', function($rootScope, benefitService, activeFundChangeService, $http, $q, $resource, Message, $filter) {
    var fondbyteResource = $resource('/ps/api/fondbyte/:orderId', null, {
        'sendExisting': {
            method: 'POST'
        }
    });

    var service = {};

    service.sendOrder = function (signature, signatureVisibleData, signatureToken, bankIdLogEntryId) {
        var order = service.getReallocationOrder();
        var request = {
            forsakringsRef: {
                utfardare: {
                    name: order.bolag,
                    orgNo: order.orgNo
                },
                typ: order.forsakringsTyp,
                forsakringsNummer: order.forsakringsNummer,
                compensationModel: order.instrumentGroupKey.compensationModel
            },
            newSparplan: {
                innehav: order.innehav
            },
            selectedPortfolio: order.modelPortfolio
        };

        if (signature) {
            request.signatureWithToken = {
                signatureToken: signatureToken,
                signature: {
                    signature: signature,
                    visibleData: signatureVisibleData
                }
            };

            request.signatureReference = {
                bankIdLogEntryId: bankIdLogEntryId
            };
        }

        var deferred = $q.defer();
        $http.post('/ps/api/fondbyte', request)
            .then(function (data) {
                service.deleteReallocationOrder();
                deferred.resolve(data.data);
            }, function () {
                deferred.reject();
            });
        return deferred.promise;
    };

    service.sendExistingOrder = function(orderId) {
        return fondbyteResource.sendExisting({
            orderId: orderId
        }, {});
    };

    service.getOrder = function(orderId) {
        return fondbyteResource.get({
            orderId: orderId
        });
    };

    service.getAllOrders = function() {
        return fondbyteResource.query().$promise.then(function(all) {
            return _.each(all, function(order) {
                order.active = order.state === 'New' || order.state === 'Sent';
            });
        });
    };

    service.createReallocationOrder = function(forsakring, useExisting) {
        if (useExisting) {
            var existing = service.getReallocationOrder();
            if (existing && existing.forsakringsNummer === forsakring.forsakringsNummer) {
                return existing;
            }
        }

        return createOrder({
            bolag: forsakring.bolag,
            orgNo: forsakring.orgNo,
            companyShortCode: forsakring.companyShortCode,
            forsakringsNummer: forsakring.forsakringsNummer,
            forsakringsTyp: forsakring.typ,
            instrumentGroupKey: forsakring.metaData.instrumentGroupKey,
            valueDate: forsakring.sparplan.valueDate,
            innehav: clone(forsakring.sparplan.innehav),
            orgInnehav: angular.copy(forsakring.sparplan.innehav)
        });
    };

    function clone(array) {
        return _.map(array, function(element) {
            return _.extend({}, element);
        });
    }

    function createOrder(spec) {
        var findByIsin = function(sp) {
            return sp.innehav.isin === this.isin;
        };
        var capitalSum = function(memo, sf) {
            // +sf.shareOfCapital parse value to int
            return memo + ((sf.shareOfCapital) ? +sf.shareOfCapital : 0);
        };
        var premiumSum = function(memo, sf) {
            // +sf.shareOfPremium parse value to int
            return memo + ((sf.shareOfPremium) ? +sf.shareOfPremium : 0);
        };

        var createSparfördelning = function(fund, shareOfCapital, shareOfPremium) {
            return {
                innehav: {
                    isin: fund.isin,
                    name: fund.name,
                    internalCode: fund.customInstitutionSecurityId
                },
                shareOfCapital: shareOfCapital,
                shareOfPremium: shareOfPremium
            };
        };

        var order = spec,
            company = $rootScope.config.insuranceCompaniesConfig.companies[spec.companyShortCode],
            maxFundsMessage = 'max_funds',
            maxFundsCapitalMessage = 'max_funds_capital',
            maxFundsPremiumMessage = 'max_funds_premium',
            maxFundsCapital,
            maxFundsPremium,
            maxFundsTotal;

        maxFundsCapital = maxFundsPremium = maxFundsTotal = $rootScope.config.insuranceCompaniesConfig.maxFundsDefault;

        if (company) {
            if (company.maxFundsCapital) {
                maxFundsCapital = company.maxFundsCapital;
            }
            if (company.maxFundsPremium) {
                maxFundsPremium = company.maxFundsPremium;
            }
            if (company.maxFundsTotal) {
                maxFundsTotal = company.maxFundsTotal;
            }
        }

        order.reset = function() {
            this.modelPortfolio = null;
            angular.copy(this.orgInnehav, this.innehav);
            Message.resolveMessage(maxFundsMessage, maxFundsCapitalMessage, maxFundsPremiumMessage);
        };

        order.addFund = function(fund) {
            Message.resolveMessage(maxFundsMessage);

            if (!fund) {
                return true;
            }

            var duplicate = _.find(this.innehav, findByIsin, fund);
            if (!duplicate) {
                if (this.innehav.length === maxFundsTotal) {
                    Message.error('fb_max_funds', $filter('translate')('fb_max_funds_message', {
                        max: maxFundsTotal
                    }), maxFundsMessage);
                    return false;
                }
                this.innehav.push(createSparfördelning(fund, 0, 0));
            }

            return true;
        };

        order.setPortfolio = function(modelPortfolio) {
            var funds = modelPortfolio.payload.funds;
            if (!funds || funds.length === 0) {
                return;
            }

            Message.resolveMessage(maxFundsMessage);
            if (funds.length > maxFundsTotal) {
                Message.error('fb_max_funds', $filter('translate')('fb_max_funds_message', {
                    max: maxFundsTotal
                }), maxFundsMessage);
                return;
            }

            this.innehav = _.map(funds, function(fund) {
                return createSparfördelning(fund, fund.shareOfCapital, fund.shareOfPremium);
            });

            this.modelPortfolio = {
                name: modelPortfolio.name,
                funds: _.map(funds, function(fund) {
                    return {
                        name: fund.name,
                        isin: fund.isin,
                        shareOfCapital: fund.shareOfCapital,
                        shareOfPremium: fund.shareOfPremium
                    };
                })
            };
        };

        order.fundExists = function(fund) {
            return _.find(this.innehav, findByIsin, fund) !== undefined ? true : false;
        };

        order.removeFund = function(fund) {
            Message.resolveMessage(maxFundsMessage, maxFundsCapitalMessage, maxFundsPremiumMessage);
            if (!fund) {
                return;
            }
            this.innehav = _.reject(this.innehav, findByIsin, fund);
            if (this.innehav.length === 0) {
                this.modelPortfolio = null;
            }
        };

        order.validateCapitalFunds = function(sparfordelning) {
            return validateFunds(sparfordelning, maxFundsCapital, 'shareOfCapital', maxFundsCapitalMessage);
        };

        order.validatePremiumFunds = function(sparfordelning) {
            return validateFunds(sparfordelning, maxFundsPremium, 'shareOfPremium', maxFundsPremiumMessage);
        };

        var validateFunds = function(sparfordelning, max, share, messageName) {
            Message.resolveMessage(messageName);

            var withinLimit = _.filter(order.innehav, function(i) {
                return i[share] > 0;
            }).length <= max;
            if (!withinLimit) {
                sparfordelning[share] = 0;
                Message.error('fb_' + messageName, $filter('translate')('fb_' + messageName + '_message', {
                    max: max
                }), messageName);
            }
            return withinLimit;
        };

        order.totalCapitalShare = function() {
            var sum = _.reduce(this.innehav, capitalSum, 0);
            return Math.round(sum);
        };

        order.totalPremiumShare = function() {
            var sum = _.reduce(this.innehav, premiumSum, 0);
            return Math.round(sum);
        };

        order.isChanged = function() {
            return !angular.equals(this.innehav, this.orgInnehav);
        };

        order.isComplete = function() {
            return order.totalCapitalShare() === 100 && order.totalPremiumShare() === 100;
        };

        order.createSignDataInput = function () {
            return this.innehav;
        };

        return order;
    }

    service.getReallocationOrder = function() {
        return sessionStorage.fondbyteOrder ? createOrder(JSON.parse(sessionStorage.fondbyteOrder)) : null;
    };

    service.saveReallocationOrder = function(order) {
        sessionStorage.fondbyteOrder = JSON.stringify(order);
    };

    service.deleteReallocationOrder = function() {
        delete sessionStorage.fondbyteOrder;
    };

    service.isElectronicFundChangeOpen = function() {
        var defer = $q.defer();
        var openPromise = defer.promise;
        $http.get('/ps/api/insurancecompany/fundchange_open').then(function (response) {
            defer.resolve((/^true$/i).test(response.data));
        });
        return openPromise;
    };

    service.isElectronicFundChangeEnabled = function() {
        if (arguments.length === 1) {
            return activeFundChangeService.isElectronicFundChangeEnabled(arguments[0]);
        }

        var order = service.getReallocationOrder();
        if (order) {
            return activeFundChangeService.isElectronicFundChangeEnabled(order.orgNo);
        }
        var defer = $q.defer();
        var disabledPromise = defer.promise;
        defer.resolve(false);
        return disabledPromise;
    };

    return service;
});
