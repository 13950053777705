angular.module('psModule').controller('FunddetailsmodalCtrl', function($q, $scope, $sce, fund, $rootScope) {

    var urlTemplate = _.template($rootScope.config.innehav.detailsUrlTemplate);

    // fund might or might not be a promise, $q.when() makes it a promise regardless.
    $q.when(fund).then(function(f) {
        $scope.fund = f;
        $scope.detailsUrl = $sce.trustAsResourceUrl(urlTemplate(f));
    });

    $scope.useSecurities = useSecurities();

    function useSecurities() {
        return $scope.$root.config && $scope.$root.config.toggles && $scope.$root.config.toggles.USE_SECURITIES === true;
    }
});
