angular.module('psModule')
    .controller('ContactCtrl', function($scope, $http, Message, $translate, $rootScope, $log) {
        var supportMailAddress;

        $translate('ps_support_mail').then(function(supportEmail) {
            supportMailAddress = supportEmail;
        });

        $scope.meta = {
            to: $rootScope.user.brokerEmail ? 'broker' : 'support'
        };
        $scope.contact = {
            name: $rootScope.user.name,
            from: $rootScope.user.email,
            phone: $rootScope.user.phone
        };

        $scope.$watch('meta.to', function(n) {
            switch (n) {
                case 'broker':
                    $scope.contact.to = $rootScope.user.brokerEmail;
                    break;
                case 'admin':
                    $scope.contact.to = $rootScope.user.adminEmail;
                    break;
                case 'support':
                    $scope.contact.to = supportMailAddress;
                    break;
                default:
                    $scope.meta.to = $rootScope.user.brokerEmail ? 'broker' : 'support';
                    $scope.contact.to = $rootScope.user.brokerEmail ? $rootScope.user.brokerEmail : supportMailAddress;
            }
        });

        $scope.sendMail = function() {
            if ($scope.contactForm.$valid) {
                $http.post('/ps/api/user/mail', { message : $scope.contact.message, to : $scope.meta.to})
                    .then(() => {
                        $translate('ps_mail_sent_to', {
                            to: $scope.contact.to
                        }).then(function(successMess) {
                            $scope.contact.message = '';
                            Message.success('ps_mail_sent', successMess, 'contact');
                            $scope.contactForm.$setPristine();
                        }, (error) => $log.error('error:', error));
                    });}
        };
    });
