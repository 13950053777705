angular.module('psModule')
    .controller('DemoLoginCtrl', function($scope, $window, DemoLoginService, $translate, UNAME_REGEX, $rootScope, ConfigService, $uiRouterGlobals, $state) {
        $scope.auth = {};

        $scope.getAlertLevel = function (level) {
            if(level.toString() === 'INFO') {
                return 'alert-info';
            }
            else if(level.toString() === 'WARNING') {
                return 'alert-warning';
            }
            return 'alert-danger';
        };

        /**
         * Demo login.
         *
         * Steps:
         *   when page loads:
         *      - Requests the available demo customers; name and ssn which is displayed in select/option
         *   on successful BankID login using broker SSN:
         *   (server checks that user is an authenicated broker/internal user and customer is a valid demo customer)
         *      - Set customer authentication token in sessionStorage.psToken
         *
         *   Hmmmm - big security risk - if psToken for broker is visible and resusable by others
         *   ..so we can set a property "$scope.loginOnBehalfOf = customerSsn" on parent controller and
         *   use that in child/directive when sending auth_collect ...server will after BankId compet perform a check on
         *   broker and then create a token on behalf instead
         */

        /*
         When PS is accessed using a link that opens a new browser tab, Safari on iOS unexpectedly closes the browser tab
         after BankID authentication is completed in the BankID app and the browser gets focus. However, if we reload the
         page once it will not be closed. So, reload the page once if accessed using touch device (not only for Safari
         on iOS, just in case...)
         */
        if ($rootScope.isTouchDevice) {
            if (!$window.localStorage.getItem('psReloaded')) {
                $window.localStorage.setItem('psReloaded', 'reloading');
                $state.reload();
            }
            else if ($window.localStorage.getItem('psReloaded') === 'reloading') {
                $window.localStorage.setItem('psReloaded', 'done');
            }
        }

        $scope.$on('hide-errors', function() {
            if ($scope.auth) {
                $scope.$apply(function() {
                    $scope.auth.md = false;
                    $scope.auth.inv = false;
                });
            }
        });

        // Start by loading available demo customers
        DemoLoginService.demoLoginCustomers().then(
            function(response) {
                $scope.demoCustomerIds = response.data;
                if ($scope.demoCustomerIds.length > 0) {
                    $scope.loginOnBehalfOf = $scope.demoCustomerIds[0].ssn;
                }
            },
            function(error) {
                $scope.error = true;
            }
        );

    })
    .factory('DemoLoginService', function($http, StorageService) {
        return {
            demoLoginCustomers: function() {
                return $http.get('/ps/api/auth/demoLoginCustomers', {
                    headers: {
                        authorization: StorageService.getPsToken()
                    }
                });
            }
        };
    });
