angular.module('psModule').controller('IntroFICtrl', function(fakta, meeting, latestMeeting, salary, $scope, $rootScope, $uibModal, $translate){

    $scope.faktainsamling = fakta;
    $scope.customerFullname = fakta.basicInformation.firstName + " " + fakta.basicInformation.surname;
    $scope.meeting = meeting.data || undefined;
    $scope.meetingDate = new Date($scope.meeting.date);
    $scope.meetingDay = $scope.meetingDate.getDate();
    $scope.latestMeeting = latestMeeting.data || undefined;
    $scope.meetingId = $scope.meeting? "&pmlMeetingId=" + $scope.meeting.id : "";
    $scope.openFaktainsamling = function(){
        if ($rootScope.config) {

            let language = $translate.use();
            $scope.fiLinkUrl = $rootScope.config.faktainsamlingurl + "?lang=" + language + $scope.meetingId;
            window.open($scope.fiLinkUrl, "_self" );
        } else {
            console.error("Saknar url till faktainsamlingen!");
        }
    };
});
