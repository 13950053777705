angular.module('psModule')
    .constant('UNAME_REGEX', /^(?:18|19|20)?\d{2}[01]\d{3}(-)?\d{4}$/)
    .controller('LoginCtrl', function($scope, $window, $translate, UNAME_REGEX, $rootScope, $uiRouterGlobals, $state, ConfigService, LoginService) {
        $scope.auth = {};
        $scope.auth.unamePattern = angular.copy(UNAME_REGEX);
        $scope.login = () => $window.location.replace('/ps/api/auth/bankid-login');

        if (!LoginService.isLoggedOut()) {
            $state.go('home');
        }

        $scope.getAlertLevel = function (level) {
            if(level.toString() === 'INFO') {
                return 'alert-info';
            }
            else if(level.toString() === 'WARNING') {
                return 'alert-warning';
            }
            return 'alert-danger';
        };

        $scope.interacted = function(field) {
            return field.$touched;
        };

        $scope.auth.inv = !!$uiRouterGlobals.params.inv;
        $scope.auth.md = !!$uiRouterGlobals.params.fail;
        $scope.auth.ssoFail = !!$uiRouterGlobals.params.ssoFail;

        /*
        When PS is accessed using a link that opens a new browser tab, Safari on iOS unexpectedly closes the browser tab
        after BankID authentication is completed in the BankID app and the browser gets focus. However, if we reload the
        page once it will not be closed. So, reload the page once if accessed using touch device (not only for Safari
        on iOS, just in case...)
        */
        if ($rootScope.isTouchDevice) {
            if (!$window.localStorage.getItem('psReloaded')) {
                $window.localStorage.setItem('psReloaded', 'reloading');
                $state.reload();
            }
            else if ($window.localStorage.getItem('psReloaded') === 'reloading') {
                $window.localStorage.setItem('psReloaded', 'done');
            }
        }

        $scope.$on('hide-errors', function() {
            if ($scope.auth) {
                $scope.$apply(function() {
                    $scope.auth.md = false;
                    $scope.auth.inv = false;
                    $scope.auth.ssoFail = false;
                });
            }
        });
    })
    .directive('hideErrors', function($rootScope) {
        return {
            restrict: 'A',
            link: function(scope, element) {
                var el = angular.element(element);
                el.on('focus', function() {
                    $rootScope.$broadcast("hide-errors");
                });

                scope.$on('$destroy', function() {
                    el.off();
                });
            }
        };
    });
