angular.module('psModule').directive('psReadMore', function($uibModal, $filter) {
	return {
		restrict: 'E',
		replace: true,
		scope: {
            header: '@',
            paragraphKeys: '='
		},
		template:'<a class="pointer hidden-print" ng-click="moreInfo()"><span translate="ps_read_more"></span></a>',
		link: function(scope, element, attrs, fn) {
            var translate = $filter('translate');

            scope.moreInfo = function () {
                $uibModal.open({
                    template:'<div><div class="modal-header" ng-if="header"><button type="button" class="close pull-right" ng-click="$dismiss()" aria-hidden="true">&times;</button><h2 class="modal-title">{{header | translate}}</h2></div><div class="modal-body"><div ng-bind-html="body"></div></div><div class="modal-footer"><button class="btn btn-default" ng-click="$dismiss()">{{\'ps_close\' | translate}}</button></div></div>',
                    controller: 'SimpleModalCtrl',
                    size: 'lg',
                    resolve: {
                        header: function () {
                            return scope.header;
                        },
                        body: function () {
                            var htmlText = "";
                            angular.forEach(scope.paragraphKeys, function (key) {
                                htmlText = htmlText + "<p>" + translate(key) + "</p>";
                            });
                            return htmlText;
                        }
                    }
                });
            };
        }
    };
});
