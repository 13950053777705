angular.module('psModule')
    .factory('DateService', function () {
        var getLatestDate = function (dateList) {
            var latestDate = null;
            dateList.forEach(function(dateArray) {
                if (dateArray) {
                    var date = moment({
                        year: dateArray[0],
                        month: dateArray[1] - 1, //month is zero indexed
                        day: dateArray[2]
                    });
                    if (latestDate === null || date > latestDate) {
                        latestDate = date;
                    }
                }
            });

            return latestDate != null ? moment(latestDate).format('YYYY-MM-DD') : null;
        };

        var getAge = function (birthdateArray) {
            if (!birthdateArray) {
                return null;
            }

            var date = moment({
                year: birthdateArray[0],
                month: birthdateArray[1] - 1, //month is zero indexed
                day: birthdateArray[2]
            });
            var now = moment();
            return now.diff(date, 'years');
        };

        return {
            getLatestDate: getLatestDate,
            getAge: getAge
        };
    });
