angular.module('psModule')

   /**
    * psTrimOrNullInput takes the view format, removes any leading and trailing white spaces
    * and returns null if there is no content left.
    */
    .directive('psTrimOrNullInput',
        function() {
            return {
                restrict: 'A',
                require: 'ngModel',
                link: function(scope, element, attrs, ngModelController) {
                    ngModelController.$parsers.push(function(data) {
                        // Convert from view format to model format
                        if (data == null) {
                            return null;
                        }
                        data = data.trim();
                        if (data.length === 0) {
                            return null;
                        }
                        return data;
                    });

                    ngModelController.$formatters.push(function(data) {
                        // No conversion is necessary when going from model format to view format
                        return data;
                    });
                }
            };
        }
    )

    /**
     * psInputDecimal replaces decimal period in the model view with a decimal comma for the view
     * and the opposite.
     */
    .directive('psModifyDecimalInput',
        function() {
            return {
                restrict: 'A',
                require: 'ngModel',
                link: function(scope, element, attrs, ngModelController) {
                    ngModelController.$parsers.push(function(viewData) {
                        // Convert from view format to model format
                        if (viewData === null || typeof(viewData) === "undefined") {
                            return viewData;
                        }

                        return viewData.toString().trim().replace(",",".");
                    });

                    ngModelController.$formatters.push(function(modelData) {
                        // Convert from model format to view format
                        if (modelData === null || typeof(modelData) === "undefined") {
                            return modelData;
                        }

                        let commaString = modelData.toString().replace(".",",");

                        let decArr = commaString.split(",");
                        if (decArr.length > 1){
                            if (decArr[1].length === 1) {
                                commaString += "0";
                            }
                        }

                        return commaString;
                    });
                }
            };
        }
    )

    /**
     * psModifyDecimalPercentageInput replaces decimal period in the model view with a decimal comma for the view
     * and the opposite. It also introduces or removes a factor of 100 since the model is the decimal value
     * and the view is the percentage.
     */
    .directive('psModifyDecimalPercentageInput',
        function() {
            return {
                restrict: 'A',
                require: 'ngModel',
                link: function(scope, element, attrs, ngModelController) {
                    ngModelController.$parsers.push(function(model) {
                        // Convert from view format to model format
                        if (model === null || typeof(model) === "undefined") {
                            return model;
                        }

                        let viewData = model.toString().replace(",",".");

                        let noDecimalMarks = viewData.split('.').length;
                        if (noDecimalMarks > 2) {
                            // Maximum one decimal mark is allowed in a decimal number
                            return viewData;
                        }

                        let positionOfDecimalMark = viewData.indexOf('.');
                        if (positionOfDecimalMark === 0 || positionOfDecimalMark === viewData.length - 1) {
                            // The decimal mark is now allowed to be the first or last character
                            return viewData;
                        }

                        let inputWithoutDecimalMark = viewData.replace('.', '');
                        let regex = new RegExp('^[0-9]*$');
                        if (!regex.test(inputWithoutDecimalMark)) {
                            // The input contains other characters than numbers and decimal marks
                            return viewData;
                        }

                        return parseFloat(viewData) / 100.0;
                    });

                    ngModelController.$formatters.push(function(modelData) {
                        // Convert from model format to view format
                        if (modelData === null || typeof(modelData) === "undefined") {
                            return modelData;
                        }

                        return (modelData * 100.0).toString().replace(".",",");
                    });
                }
            };
        }
    )
;
