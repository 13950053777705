angular.module('psModule').directive('psAlert', ['$timeout', function($timeout) {
	return {
		restrict: 'E',
		replace: true,
		scope: {

		},
		template:'<div id="ps-alert" class="text-danger bg-danger text-center" ng-show="showErrors"><i class="fa fa-exclamation-triangle"></i> <strong ng-if="lastError.title">{{lastError.title | translate}}:&nbsp;</strong>{{lastError.message | translate}}<div class="pull-right"><button class="btn btn-link btn-xs" type="button" ng-click="showErrors = false">&times;</button></div></div>',
		link: function(scope, element, attrs, fn) {

            scope.errors = [];
            scope.showErrors = false;

            scope.$on("alert:display", function (event, alert) {
                scope.errors.push(alert);
                scope.showErrors = true;
                scope.lastError = alert;
                /*
                $timeout(function () {
                    scope.showErrors = false;
                }, 120000);
                */
            });

		}
	};
}]);
