angular.module('psModule').factory('simulationService', ['$resource',
    function ($resource) {
        return $resource('/ps/api/pensionsimulation/:by/:pensionID/:field/:value', {}, {
            'getDefaultPensionSimulations': {method: 'GET', params: {by: 'default'}, isArray: false},
            'recalculate': {method: 'GET', params: {by: 'modify'}, isArray: false},
            'addSimulatedInsurance': {method: 'GET', params: {by: 'add'}, isArray: false},
            'removeSimulatedInsurance': {method: 'POST', params: {by: 'remove'}, isArray: false},
            'saveSimulation': {method: 'POST', params: {by: 'save'}, isArray: true},
            'getSavedSimulations': {method: 'GET', params: {by: 'get'}, isArray: true},
            'removeSavedSimulation': {method: 'POST', params: {by: 'delete'}, isArray: true},
            'loadSavedSimulation': {method: 'POST', params: {by: 'load'}, isArray: false},
            'hasUniqueName': {method: 'GET', params: {by: 'unique'}, isArray: false}
        });
    }
]);
