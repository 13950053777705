angular.module('psModule').directive('psDocumentsBrokerView', function() {
    return {
        restrict: 'E',
        template:'<div class="well table-responsive boxed col-xs-12"><div class="mm-panel"><table class="table table-striped table-hover"><thead><tr><th mm-tablesorter by="sortBy" reverse="sortRevert" order="\'type\'" class="sortable" ng-class="{sortedBy: sortBy===\'type\'}">{{\'ps_documents_column_type\' | translate}}</th><th mm-tablesorter by="sortBy" reverse="sortRevert" order="\'description\'" class="sortable" ng-class="{sortedBy: sortBy===\'description\'}">{{\'ps_documents_column_description\' | translate}}</th><th mm-tablesorter by="sortBy" reverse="sortRevert" order="\'sender\'" class="sortable" ng-class="{sortedBy: sortBy===\'sender\'}">{{\'ps_documents_column_sender\' | translate}}</th><th mm-tablesorter by="sortBy" reverse="sortRevert" order="\'date\'" class="sortable" ng-class="{sortedBy: sortBy===\'date\'}">{{\'ps_documents_column_date\' | translate}}</th><th>{{\'ps_documents_column_hide\' | translate}}</th></tr></thead><tbody><tr style="cursor:pointer;" ng-click="openDocument(document)" ng-repeat="document in documents | orderBy:sortBy:sortRevert | filter: {type: documentType}" ng-mouseover="showPopover=true;" ng-mouseleave="showPopover=false;"><td ng-style="document.hidden ? {\'text-decoration\': \'line-through\'} : {}"><span>{{\'ps_documents_type_\' + document.type | translate}}</span></td><td ng-style="document.hidden ? {\'text-decoration\': \'line-through\'} : {}">{{document.description}}</td><td>{{document.sender}}</td><td>{{document.date | date:\'yyyy-MM-dd\'}}</td><td><a href ng-if="document.hidable" ng-click="changeVisibility($event, document)">{{document.hidden ? (\'ps_documents_show\' | translate) : (\'ps_documents_hide\' | translate)}}</a></td></tr></tbody></table></div></div>',
        transclude: true,
        scope: {
            documents: '=documents',
            documentType: '=documentType',
            openDocument: '=openDocument',
            changeVisibility: '=changeVisibility'
        }
    };
});
