angular.module('maxfonder')
    .controller('DiskConfirmationMaxfonderCtrl', function ($scope, $location, $filter, $state, DiskService) {

        $scope.registration = DiskService.getSavedRegistration();

        // show error if no existing registration
        $scope.hasRegistration = $scope.registration !== null && $scope.registration.mode;

        $scope.pdfId = $scope.registration.pdfId;
});
