angular.module('psModule').controller('GroupinsuranceCtrl', function ($scope, $translate, groupBenefitService) {
    $scope.dataLoaded = false;
    $scope.gruppForsakringarLoaded = false;
    $scope.wisenticLinkLoaded = false;
    $scope.gruppforsakringar = {};
    $scope.wisentic = {};

    groupBenefitService.getWisenticLink().then(link => {
        $scope.wisentic = link.url;
        $scope.wisenticLinkLoaded = true;
        if($scope.gruppForsakringarLoaded){
            $scope.dataLoaded = true;
        }
    });

    groupBenefitService.getGruppforsakring().then(function(gruppforsakringar){
        $scope.gruppforsakringar = gruppforsakringar;
        $scope.gruppForsakringarLoaded = true;
        if($scope.wisenticLinkLoaded) {
            $scope.dataLoaded = true;
        }
    });


});
