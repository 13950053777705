angular.module('maxfonder').factory('MaxfonderInstrumentService', function ($resource, $rootScope) {

    var service = {};

    service.instrumentsResource = $resource('/ps/api/instruments', {}, {});

    var orgNoConfig = $rootScope.config.maxfonderUniverse.orgNo;
    var universeIdentifierConfig = $rootScope.config.maxfonderUniverse.universeIdentifier;

    service.getInstrumentsForUnivers = function () {
       var instruments = service.instrumentsResource.query({
            orgNo: orgNoConfig,
            universeIdentifier: universeIdentifierConfig
        });
        return instruments;
    };

	return service;
});
