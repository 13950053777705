angular.module('psModule').factory('DocumentStatefulService', function ($resource, $q, DocumentService) {

    let documents = [];
    let unreadDocuments = [];
    let reload = true; // default true

    let getDocuments = function(){
        return documents;
    };

    let setReload = function(){
        reload = true;
    };

    let toggleRead = function(document, read){
        let deferred = $q.defer();
        DocumentService.save({id: document.documentViewId, read : read}, () => {
            reload = true;
            deferred.resolve(true);
        });
        return deferred.promise;
    };

    function YourDocuments(documents, unreadDocuments) {
        this.documents = documents;
        this.unreadDocuments = unreadDocuments;
    }

    function retrieveYourDocuments(data){
        documents = data.documents;
        unreadDocuments = data.unreadDocuments;
        return new YourDocuments(documents, unreadDocuments);
    }

    let getYourDocuments = function(){
        let deferred = $q.defer();
        if(!reload){
            // Use preloaded data
            deferred.resolve(new YourDocuments(documents, unreadDocuments));
        }else{
            // retrieve new data
            DocumentService.getYourDocuments({},function (data) {
                reload = false;
                deferred.resolve(retrieveYourDocuments(data));
            });
        }
        return deferred.promise;
    };

    return {
        getYourDocuments : getYourDocuments,
        getDocuments: getDocuments,
        toggleRead : toggleRead,
        setReload : setReload
    };
});
