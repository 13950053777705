angular.module('psModule').filter('formatduration', function ($translate) {
    // Format duration expressed in months to year and months, e.g 40 months will be displayed as 3 years and 4 months
    function formatDuration(inputValue, unit /* days | months */) {
        inputValue = inputValue || 0;
        let numericValue = -1;
        if (typeof inputValue === 'string') {
            const numberFromString = parseInt(inputValue);
            numericValue = !isNaN(numberFromString) ? numberFromString : -1;
        } else if (typeof inputValue === 'number') {
            numericValue = inputValue;
        }
        if (numericValue === -1) {
            return inputValue;
        }

        if (!unit || unit === 'months') {
            const years = Math.floor(numericValue / 12);
            const reminderMonths = numericValue % 12;

            // Note this text is pluralized with angular-translate-interpolation-messageformat
            // Tip: use https://format-message.github.io/icu-message-format-for-translators/editor.html to test translation strings
            return $translate.instant(
                'ps_year_and_months_pluralized',
                {YEARS: years, MONTHS: reminderMonths},
                'messageformat' // Need to specify that it should be pluralized, see https://angular-translate.github.io/docs/#/guide/14_pluralization
            );
        } else if (unit === 'days') {
            return $translate.instant(
                'ps_days_pluralized',
                {DAYS: numericValue},
                'messageformat' // Need to specify that it should be pluralized, see https://angular-translate.github.io/docs/#/guide/14_pluralization
            );
        }
    }

    return formatDuration;
});
