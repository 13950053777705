angular.module('psModule')
    .factory('TogglzService',
        function ($q, $http) {
            return {
                enabled: function enabled() {
                    return $http.get('/ps/api/documents/fileUploadIsEnabled');
                }
            };
        }
    );
