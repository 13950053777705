angular.module('psModule')
    .controller('identificationCtrl', function($scope, $rootScope, $http) {

        $scope.identifications = []

        $http.get('/ps/api/bankid/ident_dates?pnr=' + $rootScope.user.ssn)
            .then(function (response) {
                if(response.status  === 200) {
                    $scope.identifications = response.data
                } else {
                    $scope.identifications = []
                }
            });
    });






