angular.module('psModule').factory('calculationParamsService', function ($q, $resource) {

    var resource = $resource('/ps/api/calcparam', {}, {});

    var calculationParamsFuture = $q.defer();
    var calculationParamsPromise = calculationParamsFuture.promise;

    resource.get().$promise
        .then(function (data) {
            calculationParamsFuture.resolve(data);
        }, function (error) {
            calculationParamsFuture.reject(error);
        });

    var calculationParamService = {};

    calculationParamService.get = function () {
        return calculationParamsPromise;
    };

    return calculationParamService;

});
