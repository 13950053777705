angular.module('psBankId', ['ngResource'])
    .factory('BankIdService', function($resource) {
        return $resource('/ps/api/bankid/:action', {}, {
            auth: {
                method: 'POST',
                params: {
                    action: 'auth'
                }
            },
            authCollect: {
                method: 'POST',
                params: {
                    action: 'auth_collect'
                }
            },
            sign: {
                method: 'POST',
                params: {
                    action: 'sign'
                }
            },
            signCollect: {
                method: 'POST',
                params: {
                    action: 'sign_collect'
                }
            },
            signQrData: {
                method: 'POST',
                params: {
                    action: 'sign_qr_data'
                }
            },
            'ident': {
                method: 'POST',
                params: {
                    action: 'ident'
                }
            },
            'identCollect': {
                method: 'POST',
                params: {
                    action: 'ident_collect'
                }
            }
        });
    });
