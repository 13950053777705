angular.module('psModule')
    .directive('psModelPortfolioLinks', function($uibModal, $filter) {
        return  {
            restrict: 'E',
            template:'<div class="well hidden-print"><p><a class="pointer" ng-click="showPortfolioInfo()" translate="fb_portfolio_read_more"></a></p><p><a class="pointer" ng-click="showPortfolioComparison()" translate="fb_portfolio_general_comparison"></a></p></div>',
            link: function(scope, element, attrs, fn) {
                var translate = $filter('translate');

                scope.showPortfolioInfo = function() {
                    $uibModal.open({
                        template:'<div><div class="modal-header" ng-if="header"><button type="button" class="close pull-right" ng-click="$dismiss()" aria-hidden="true">&times;</button><h2 class="modal-title">{{header | translate}}</h2></div><div class="modal-body"><div ng-bind-html="body"></div></div><div class="modal-footer"><button class="btn btn-default" ng-click="$dismiss()">{{\'ps_close\' | translate}}</button></div></div>',
                        controller: 'SimpleModalCtrl',
                        size: 'lg',
                        resolve: {
                            header: function() {
                                return translate('fb_portfolio_read_more_header');
                            },
                            body: function() {
                                return "<p>" + translate('fb_portfolio_read_more1') + '</p><p>' + translate('fb_portfolio_read_more2') + '</p><p>' + translate('fb_portfolio_read_more3');
                            }
                        }
                    });
                };

                scope.showPortfolioComparison = function() {
                    $uibModal.open({
                        template:'<div><div class="modal-header"><button type="button" class="close pull-right" ng-click="$dismiss()" aria-hidden="true">&times;</button><h2 class="modal-title">{{\'fb_portfolio_general_comparison_header\' | translate}}</h2></div><div class="modal-body"><ps-generic-model-portfolios></ps-generic-model-portfolios></div><div class="modal-footer"><button class="btn btn-default" ng-click="$dismiss()">{{\'ps_close\' | translate}}</button></div></div>',
                        size: 'xl'
                    });
                };
            }
        };
    });
