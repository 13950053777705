angular.module('psModule').directive('psPieChart', function() {
	return {
		restrict: 'E',
        replace: true,
        scope: {
            items: '=',
            id: '@'
        },
        template: '<div style="margin: 0 auto"></div>',
        link: function (scope, element, attrs) {
            var chart = new Highcharts.Chart({
                chart: {
                    renderTo: scope.id,
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false
                },
                title: {
                    text: ''
                },
                tooltip: {
                    enabled: false,
                    pointFormat: '<b>{point.percentage:.2f}%</b>'
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: false,
                        cursor: 'cursor',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.percentage:.2f} %'
                        }
                    }
                },
                series: [{
                    type: 'pie',
                    name: '',
                    data: scope.items
                }]
            });

            scope.$on("$destroy", function() {
                chart = undefined;
            });
        }
    };
})
    .directive('psPieChartLegend', function() {

        return {
            restrict: 'E',
            replace: false,
            scope: {
                items: '=',
                id: '@',
                title: '@',
                width: '@',
                height: '@',
                showTooltip: '@',
                hideEmptySegments: '@',
                legend: '=?'
            },
            link: function (scope, element, attrs) {

                var filterData = function (items, hideEmptySegments) {
                    var modified = angular.copy(items);
                    if (hideEmptySegments) {
                        modified = _.filter(modified, function (pair) {
                            return pair[1] > 0;
                        });
                    }
                    return modified;
                };

                var data = filterData(scope.items, scope.hideEmptySegments);

                var chart = new Highcharts.Chart({
                    chart: {
                        renderTo: scope.id,
                        width: scope.width,
                        height: scope.height,
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        backgroundColor: null
                    },
                    legend: {
                        layout: scope.legend && scope.legend.layout ? scope.legend.layout : 'horizontal',
                        align: scope.legend && scope.legend.align ? scope.legend.align : 'center',
                        verticalAlign: scope.legend && scope.legend.verticalAlign ? scope.legend.verticalAlign : 'bottom',
                        x: scope.legend && scope.legend.x ? scope.legend.x : 0,
                        y: scope.legend && scope.legend.y ? scope.legend.y : 0,
                        floating: scope.legend && scope.legend.floating ? scope.legend.floating : false
                    },
                    title: {
                        text: scope.title,
                        margin: 0,
                        style: {
                            color: 'black',
                            fontWeight: 'bold',
                            fontSize: 12
                        }
                    },
                    lang: {
                        noData: "No data"
                    },
                    tooltip: {
                        enabled: scope.showTooltip,
                        pointFormat: '<b>{point.percentage:.2f} %</b>'
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: false,
                            cursor: 'cursor',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        },
                        series: {
                            point: {
                                events: {
                                    legendItemClick: function () {
                                        return false; // <== returning false will cancel the default action
                                    }
                                }
                            }
                        }
                    },
                    series: [{
                        type: 'pie',
                        name: '',
                        data: data
                    }]
                });

                scope.$on("$destroy", function() {
                    chart = undefined;
                });

                scope.$watch('items', function () {
                    var values = filterData(scope.items, scope.hideEmptySegments);
                    chart.series[0].setData(values);
                    chart.redraw();
                });
            }
        };
    });
