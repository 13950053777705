angular.module('psModule').directive('psGraphRiskAvkastning', function($filter, GraphService) {
    return {
        restrict: 'E',
        replace: false,
        scope: {
            id: '@',
            highlightPoint: '@'
        },
        link: function (scope, element, attrs) {
            var graphBlue = GraphService.getLightBlue();

            var getValue = function (x) {
                return (10-x)*x;
            };

            var chart = new Highcharts.Chart({
                chart: {
                    renderTo: scope.id,
                    type: 'spline',
                    width: 160,
                    height: 150
                },
                title: {
                    text: ''
                },
                subtitle: {
                    text: ''
                },
                xAxis: {
                    title: {
                        text: $filter('translate')('ps_portfolio_graph_risk')
                    },
                    labels: {
                        formatter: function () {
                            return '';
                        }
                    },
                    lineWidth: 1,
                    lineColor: "black",
                    tickWidth: 0
                },
                yAxis: {
                    title: {
                        text: $filter('translate')('ps_portfolio_graph_return')
                    },
                    labels: {
                        formatter: function () {
                            return '';
                        }
                    },
                    lineWidth: 1,
                    lineColor: "black",
                    tickWidth: 0,
                    gridLineWidth: 0,
                    max: 21
                },
                tooltip: {
                    enabled: false
                },
                plotOptions: {
                    spline: {
                        marker: {
                            radius: 5,
                            lineColor: graphBlue,
                            lineWidth: 0
                        },
                        showInLegend: false
                    }
                },
                series: [{
                    name: '',
                    marker: {
                        symbol: 'circle'
                    },
                    data: [
                        {
                            y: getValue(0),
                            marker: { enabled: false }
                        }, {
                            y: getValue(1),
                            color: scope.highlightPoint === '1' ? 'red' : graphBlue
                        }, {
                            y: getValue(2),
                            color: scope.highlightPoint === '2' ? 'red' : graphBlue
                        }, {
                            y: getValue(3),
                            color: scope.highlightPoint === '3' ? 'red' : graphBlue
                        }, {
                            y: getValue(4),
                            color: scope.highlightPoint === '4' ? 'red' : graphBlue
                        }, {
                            y: getValue(5),
                            marker: { enabled: false }
                        }]
                }]
            });

        scope.$on("$destroy", function() {
            chart = undefined;
        });
    }
    };
});


