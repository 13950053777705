angular.module('psModule').factory('instrumentByIsinService',['$resource', '$cacheFactory', '$q', function($resource, $cacheFactory, $q) {

    var service = {};

    var instrumentsByIsinCache = $cacheFactory('instrumentByIsinCache');

    service.instrumentsByIsinResource = $resource('/ps/api/instruments/:isin', {}, {});

    service.getInstrumentByIsin = function(isin) {
        var instrument = instrumentsByIsinCache.get(isin);
        if (!instrument) {
            instrument = service.instrumentsByIsinResource.get({isin: isin});
            instrumentsByIsinCache.put(isin, instrument);
        }
        return instrument;
    };

    service.getInstrument = function(isin) {
        if (_.isEmpty(isin)) {
            return null;
        }

        var promise = instrumentsByIsinCache.get(isin);

        if (!promise) {
            promise = $q(function(resolve, reject) {
                service.getInstrumentByIsin(isin).$promise.then(function(instrument) {
                    if (_.isEmpty(instrument)) {
                        resolve(null);
                    } else {
                        resolve(instrument);
                    }
                }, reject);
            });
            instrumentsByIsinCache.put(isin, promise);
        }
        return promise;
    };

    return service;
}]);
