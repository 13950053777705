angular.module('psModule').filter('contentfile', function($translate){
    // content file filter for language substitution at {lang}, default CMS content path is "/app"
    function contentFileFilter(input, path) {
        var contentPath = '/ps/api/content' + (path ? path : '/app');
        var file = input.replace(/{lang}/, $translate.use());
        return contentPath + '/' + file;
    }
    contentFileFilter.$stateful = true; // If language is changed on same page we need this to update filter output, see https://docs.angularjs.org/guide/filter#stateful-filters
    return contentFileFilter;
});
