angular.module('fondbyte').controller('ConfirmationCtrl',
    function($scope, $uiRouterGlobals, ChangeService, $translate, $uibModal, Message, CounselingSessionService, electronicFundChangeEnabled, $rootScope) {
        $scope.pdfPrinted = false;
        $scope.confirm = {
            checked: false
        };
        $scope.sendError = false;
        $scope.sendOrderInProgress = false;
        $scope.documentFundChangeSuccess = false;
        $scope.documentFundChangeInProgress = false;
        $scope.enableBrokerOptions = $rootScope.user.broker;

        $scope.order = ChangeService.getOrder($uiRouterGlobals.params.orderId);

        $scope.order.$promise.then(function(order) {
            $scope.enablePaperOrderSection = order.type === 'Paper' || order.type === 'PaperElectronic';
            $scope.enableBrokerPaperOrderSection = $scope.enableBrokerOptions && electronicFundChangeEnabled && (order.type === 'Paper' && order.state === 'Completed');
        });

        $scope.sendOrder = function() {
            $scope.sendOrderInProgress = true;
            ChangeService.sendExistingOrder($uiRouterGlobals.params.orderId).$promise.then(function() {
                $scope.enableBrokerPaperOrderSection = false;
                Message.success('fb_broker_send_order_success_title', 'fb_broker_send_order_success', 'orderSendSuccess');
            }, function() {
                $scope.sendError = true;
                $scope.sendOrderInProgress = false;
            });
        };

        $scope.disableSendOrder = function() {
            return !$scope.confirm.checked ||  $scope.sendOrderInProgress;
        };

        $scope.setPdfPrinted = function() {
            $scope.pdfPrinted = true;
            return true;
        };


    });
