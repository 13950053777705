angular.module('psModule').controller('momentModalCtrl',
    function($scope, instrumentByIsinService, benefitService, $uibModalInstance, fbelopp, $sce) {

    $scope.fbelopp = fbelopp;

    benefitService.getFbesked().then(function(fbesked) {

        // Note: there will only be a content in fbesked.forsakringar when we fetch fbesked
        // from PmlWeb, and not from Benefit Summary, but that's okay since this modal should
        // only be used for the old solution where the fbesked comes from PmlWeb.
        $scope.forsakring = _.find(fbesked.forsakringar, function (f) {
            return f.id === fbelopp.forsakringsRef;
        });

        $scope.moment = _.find($scope.forsakring.moment, function (m) {
            return m.id === fbelopp.momentRef;
        });

        $scope.sparplan = $scope.moment.sparplan ? $scope.moment.sparplan : $scope.forsakring.sparplan;

    });

    $scope.getFund = function (sparfordelning) {
        return instrumentByIsinService.getInstrument(sparfordelning.innehav.isin);
    };

    $scope.fullDescHtml = $sce.trustAsHtml(fbelopp.fullDescriptionHtml);

    var removeEventListener = $scope.$on("$locationChangeStart", function() {
        removeEventListener();
        $uibModalInstance.dismiss();
    });

});
