angular.module('psModule')
    .controller('FundInfoCtrl', function($scope, $q, assetService){

    $scope.dataLoaded = false;
    $scope.fundsWithoutHistory = [];
    $scope.fundsWithHistory = [];
    $scope.fundsWithoutHistorySortBy = 'name';
    $scope.fundsWithoutHistorySortRevert = false;
    $scope.fundsWithHistorySortBy = 'name';
    $scope.fundsWithHistorySortRevert = false;
    $scope.fundsWithoutHistorySum = 0;
    $scope.fundsWithHistorySum = 0;

    function hasNoHistory(fund) {
        return _.isEmpty(fund.securityId) && _.isEmpty(fund.universeId);
    }

    $scope.getToday = function () {
        return new Date();
    };

    function getIndex(name, array) {
        for (var i = 0; i < array.length; i++) {
            if (array[i].name === name) {
                return i;
            }
        }
        return -1;
    }

    function getFundsFromTillgangsvarden(tillgangsvarden) {
        var funds = [];
        for (var i = 0; i < tillgangsvarden.length; i++) {
            var tillgang = tillgangsvarden[i].tillgang;
            var kapitalskal = tillgangsvarden[i].kapitalskal;
            var containerName = kapitalskal.name;
            if (kapitalskal.typ === 'Egna_inmatningar') {
                containerName = 'ps_assets_kapitalskal_own';
            }
            if (tillgang.type === 'Fond') {
                var index = getIndex(tillgang.name, funds);
                if (index === -1) {
                    funds.push({
                        name: tillgang.name,
                        securityId: tillgang.securityId,
                        universeId: tillgang.universeId,
                        containerNames: [containerName],
                        valueSEK: tillgangsvarden[i].value,
                        rating: tillgang.rating,
                        risk: tillgang.risk,
                        sharesDate: tillgang.sharesDate,
                        utveckling: tillgang.utveckling,
                        isin: tillgang.isin
                    });
                } else {
                    funds[index].valueSEK += tillgangsvarden[i].value;
                    funds[index].containerNames.push(containerName);
                }
            }
        }
        return funds;
    }

    function initializeXray(fundsWithHistory) {

        $scope.isinFunds = function () {
            return fundsWithHistory.filter(function (fund) {
                return !_.isEmpty(fund.isin) && fund.valueSEK > 0; // Condition should be aligned with canUseAssetInAnalysis() in assets.js
            }).map(function (fund) {
                return {isin: fund.isin, value: fund.valueSEK};
            });
        };
    }

    assetService.get().$promise.then(function(tillgangsvarden) {
        var funds = getFundsFromTillgangsvarden(tillgangsvarden);
        $scope.fundsWithHistory = [];
        $scope.fundsWithoutHistory = [];
        for (var i=0; i<funds.length; i++) {
            // "with history" means that fund details such as securityId and universeId could by fetched by ISIN from MS via instrumentservice
            if (hasNoHistory(funds[i])) {
                $scope.fundsWithoutHistory.push(funds[i]);
                $scope.fundsWithoutHistorySum += funds[i].valueSEK;
            } else {
                $scope.fundsWithHistory.push(funds[i]);
                $scope.fundsWithHistorySum += funds[i].valueSEK;
            }
        }
        initializeXray($scope.fundsWithHistory);
        $scope.dataLoaded = true;
    });
});
