angular.module('psModule').component('displayContent', {
    template: `
            <span ng-if="$ctrl.displayObject.value === undefined || $ctrl.displayObject.value === null">
                {{$ctrl.showUndefinedAsUnknown ? ('bs_constant_unknown_translation' | translate) : '' }}
            </span>
            <span ng-if="$ctrl.displayObject.value !== undefined && $ctrl.displayObject.value !== null"
                ng-switch on="$ctrl.displayObject.valueType">
                <span ng-switch-when="TIME_MONTHS">{{$ctrl.displayObject.value | formatduration:months }}</span>
                <span ng-switch-when="TIME_DAYS">{{$ctrl.displayObject.value | formatduration:days }}</span>
                <span ng-switch-when="AMOUNT"><ps-amount value="$ctrl.displayObject.value | translate" currency="kr"/></span>
                <span ng-switch-default>{{$ctrl.displayObject.value | translate}}</span>
            </span>
        `,
    bindings: {
        // displayObject is expected to be an object with the structure
        // {
        //   value: string,
        //   valueType: string
        // }
        // where 'valueType' can have the values:
        // - AMOUNT: 'value' is treated as an amount and displayed with the 'ps-amount' component
        // - TIME_MONTHS: 'value' is treated as months and formatted using the 'formatduration' filter
        // - TIME_DAYS: 'value' is treated as days and formatted using the 'formatduration' filter
        // - other value types have no special meaning and the value is expected to be translation key and will be
        //   piped through the translate filter
        displayObject: '<',
        showUndefinedAsUnknown: '<'
    }
});
