angular.module('maxfonder').factory('RegistrationService', function ($q, $http, $resource, $rootScope, BlobService) {

    var dataTransformer = function (data, headers) {
        return {
            data: data,
            depositNumber: headers('x-maxfonder-depositnumber')
        };
    };

    var resource = $resource(null, null, {
        'generatePdf': {url: '/ps/api/maxfonder/depositregistration/pdf', method: 'POST', responseType: 'arraybuffer', transformResponse: dataTransformer},
        'createDepositAndGeneratePdf': {url: '/ps/api/maxfonder/depositregistration', method: 'POST', responseType: 'arraybuffer', transformResponse: dataTransformer},
        'createDepositAndSend': {url: '/ps/api/maxfonder/depositregistration/signed', method: 'POST'}
    });

    function formatCompensation(compensation) {
        var formatFee = function (value) {
            return !value ? value : value.replace(',', '.');
        };

        compensation.fixedFee = formatFee(compensation.fixedFee);
        compensation.dynamicFee = formatFee(compensation.dynamicFee);
        return compensation;
    }

    function createRegistration(savedRegistration) {
        var registration;

        if (savedRegistration) {
            registration = savedRegistration;
        } else {
            registration = {
                ssn: $rootScope.user.ssn,
                depositType: null,
                bank: null,
                bankAccountClearingNumber: null,
                bankAccountNumber: null,
                broker: null,
                compensation: {
                    type: null,
                    fixedFee: null,
                    dynamicFee: null
                },
                pdf:null,
                pdfId: null,
                pending: false,
                submitted: false,
                eSigningSelected: false
            };
        }

        registration.createSignDataInput = function () {
            var signDataInput = {
                depositType: registration.depositType.id,
                bank: registration.bank,
                bankAccountClearingNumber: registration.bankAccountClearingNumber,
                bankAccountNumber: registration.bankAccountNumber,
                compensation: formatCompensation(registration.compensation)
            };

            return signDataInput;
        };

        return registration;
    }

    var service = {};

    service.saveRegistration = function (registration) {
        sessionStorage.maxfonderRegistration = JSON.stringify(registration);
    };

    service.deleteRegistration = function () {
        sessionStorage.removeItem('maxfonderRegistration');
    };

    service.getSavedRegistration = function () {
        if (sessionStorage.maxfonderRegistration) {
            var savedRegistration = createRegistration(JSON.parse(sessionStorage.maxfonderRegistration));
            if (savedRegistration.ssn === $rootScope.user.ssn) {
                return savedRegistration;
            }
        }
        return null;
    };

    service.getOrCreateRegistration = function () {
        var savedRegistration = service.getSavedRegistration();
        if (savedRegistration) {
            return savedRegistration;
        }
        return createRegistration();
    };

    service.hasPendingSavedRegistration = function () {
        var savedRegistration = service.getSavedRegistration();
        if (savedRegistration) {
            return savedRegistration.pending;
        }
        return false;
    };

    service.pendingSavedRegistrationSubmitted = function () {
        var savedRegistration = service.getSavedRegistration();
        if (savedRegistration && savedRegistration.pending) {
            savedRegistration.pending = false;
            savedRegistration.submitted = true;
            service.saveRegistration(savedRegistration);
        }
    };

    service.formatRequest = function (registration) {
        var request = {
            depositType: registration.depositType.id,
            bank: registration.bank,
            bankAccountClearingNumber: registration.bankAccountClearingNumber,
            bankAccountNumber: registration.bankAccountNumber,
            broker: {
                name: registration.broker.name,
                code: registration.broker.code
            },
            compensation: formatCompensation(registration.compensation)
        };

        // add possible deposit number stored on bekraftelse page
        if (registration.pdfResult && registration.pdfResult.depositNumber) {
            request.depositNumber = registration.pdfResult.depositNumber;
        }

        return request;
    };

    function createPdfResult(response) {
        return {
            pdf: BlobService.createPdfBlobUrl(response.data),
            depositNumber: response.depositNumber
        };
    }

    service.generatePDFForPrint = function (registration) {
        var deferred = $q.defer();
        resource.generatePdf(service.formatRequest(registration)).$promise.then(
            function (response) {
                deferred.resolve(createPdfResult(response));
            }, function () {
                deferred.reject();
            }
        );
        return deferred.promise;
    };

    service.createDepositAndGeneratePDFForPrint = function (registration) {
        var deferred = $q.defer();
        resource.createDepositAndGeneratePdf(service.formatRequest(registration)).$promise.then(
            function (response) {
                deferred.resolve(createPdfResult(response));
            }, function () {
                deferred.reject();
            }
        );
        return deferred.promise;
    };

    service.createDepositAndSend = function (registration, signature, signatureVisibleData, signatureToken, ipAddress, bankIdLogEntryId) {

        var formattedRequest = service.formatRequest(registration);

        formattedRequest.signatureWithToken = {
            signatureToken: signatureToken,
            signature: {
                signature: signature,
                visibleData: signatureVisibleData
            }
        };
        formattedRequest.signatureReference = {
            bankIdLogEntryId: bankIdLogEntryId,
            ipAddress: ipAddress
        };

        var deferred = $q.defer();
        resource.createDepositAndSend(formattedRequest).$promise.then(
            function (response) {
                deferred.resolve(response.documentId);
            }, function () {
                deferred.reject();
            }
        );
        return deferred.promise;
    };

    return service;
});
