angular.module('psModule')
    .factory('PortfolioService', function ($filter, $resource) {

        var modelPortfolioResource = $resource('/ps/api/portfolio/:company', {}, {
            'getModelPortfolio': {method: 'GET', params: {}, isArray: true}
        });

        var convertDate = function (dateArray) {
            if (!dateArray || !dateArray.length) {
                return null;
            }
            return new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
        };

        var decoratePortfolio = function (data) {
            if (!data) {
                return;
            }

            if (data.payload && data.payload.funds) {
                _.each(data.payload.funds, function (f) {
                    f.shareOfCapital = f.innehav;
                    f.shareOfPremium = f.premiums;
                });
            }

            data.reportDate = convertDate(data.reportDate);
            data.startDate = convertDate(data.startDate);
            data.lastUpdate = convertDate(data.lastUpdate);
        };

        var getModelPortfolios = function (insuranceCompanyOrgNo) {
            return modelPortfolioResource.getModelPortfolio({company: insuranceCompanyOrgNo}, function (portfolios) {
                _.each(portfolios, function (portfolio) {
                    decoratePortfolio(portfolio);
                });
            });
        };

        return {
            getModelPortfolios: getModelPortfolios
        };

    });
