angular.module('psModule').directive('psPortfolioAnalysisLink', function (StorageService, $rootScope) {

    var linker = function($scope, element, attrs, fn) {

        function isinCodesAndValues() {
            return _.chain(fundsToAnalyze()).groupBy('isin').map(function(funds, key){
                return { isin: key, value: valueSum(funds)};
            }).sortBy('value').value();
        }

        function fundsToAnalyze() {
            return $scope.funds()();
        }

        function hasFunds() {
            return fundsToAnalyze().length > 0;
        }

        // TODO: underscore lacks sum() and sumBy() - we should use lodash instead :-(
        function valueSum(funds) {
            return _.reduce(funds, function(memo, fund){
                return memo + fund.value;
            }, 0);
        }

        $scope.authorized = function () {
            return $rootScope.user.broker;
        };

        $scope.disabled = function () {
            return !hasFunds();
        };

        $scope.analyzeParams = function () {
            if (!hasFunds()) {
                return undefined;
            }

            const isinWithValues = isinCodesAndValues().reverse(); // Highest value funds first
            const isinCodes = _.map(isinWithValues, 'isin').join('|');
            const values    = _.map(isinWithValues, 'value').join('|');
            const params = {
                isinCodes: isinCodes,
                values: values,
                auth: StorageService.getPsToken()
            };

            return params;
        };
    };

	return {
		restrict: 'E',
		replace: true,
		scope: {
		    funds: '&'
		},
		template:'<span><a class="btn btn-large btn-success" ng-disabled="disabled()" ui-sref="portfolioanalysis(analyzeParams())" target="_blank">{{\'ps_assets_analyze_launch\' | translate}}</a></span>',
		link: linker
	};
});
