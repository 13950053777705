angular.module('psModule')
    .controller('BankIdCtrl', function($scope, BankIdService, $timeout, $interval, $window, $element, $filter, $rootScope, UNAME_REGEX, $log, $state, LoginService) {

        /**
         * The parent Controller can set property '$scope.loginOnBehalfOf' as the pnr/ssn of another user/customer
         * to indicate that this BankId login should authenticate that customer instead.
         *
         * This requires that the BankId user is a broker(internal user).
         * The Demo login uses this functionality.
         */
        var pollSleepMillis = $scope.mobile ? 9000 : 3000;
        var pollIntervalMillis = 3000;
        var pollInterval;
        $scope.authStarted = false;
        $scope.authCompleted = false;
        $scope.infoMessage = '';
        $scope.errorMessage = '';
        $scope.mobile_infoMessage = '';
        $scope.mobile_errorMessage = '';
        $scope.unamePattern = angular.copy(UNAME_REGEX);
        $scope.form = {
            openMbid: $rootScope.isTouchDevice
        };

        $scope.interacted = function(field) {
            return field.$touched;
        };

        function resetMessages() {
            $scope.infoMessage = '';
            $scope.errorMessage = '';
            $scope.mobile_infoMessage = '';
            $scope.mobile_errorMessage = '';
        }

        function cancelPoll() {
            $interval.cancel(pollInterval);
            pollInterval = undefined;
            hideLoader();
            resetMessages();
            $scope.authStarted = false;
        }

        function launchBankIdApp() {
            $scope.bankIdUrl = 'bankid:///?autostarttoken=' + $scope.orderResponse.autoStartToken + '&redirect=';

            if ($rootScope.isTouchDevice || (window.navigator.userAgent && window.navigator.userAgent.toLowerCase().indexOf("chrome") > -1)) {
                $window.location = $scope.bankIdUrl;
            } else {
                $element.find('iframe').attr('src', $scope.bankIdUrl);
            }
        }

        function handleComplete(response) {
            $scope.authCompleted = true;
            cancelPoll();
            LoginService.login(response.token);
        }

        function handleError(error) {
            cancelPoll();
            displayBankIdMessage(error);
        }

        function handleInvalidParameters() {
            cancelPoll();
            $log.info('Login already collected');
        }

        function handleUnauthorized() {
            cancelPoll();
            var message = $filter('translate')('ps_login_unauthorized');
            displayMessage(message, true);
        }

        function displayBankIdMessage(response) {
            var message = $filter('translate')('bankid_' + msgPrefix() + response.status);
            displayMessage(message, response.isError);
        }

        function displayMessage(message, isError) {
            var messageType = isError ? 'errorMessage' : 'infoMessage';
            $scope[msgPrefix() + messageType] = message;
        }

        function msgPrefix() {
            return $scope.mobile ? 'mobile_' : '';
        }

        function handleResponse(status) {
            var statusHandlers = {
                'OUTSTANDING_TRANSACTION': displayBankIdMessage,
                'USER_SIGN': displayBankIdMessage,
                'COMPLETE': handleComplete,
                'NO_CLIENT': displayBankIdMessage,
                'STARTED': displayBankIdMessage,
                'USER_CANCEL': handleError,
                'INVALID_PARAMETERS': handleInvalidParameters,
                'INVALID_PS_USER': handleUnauthorized
            };

            return statusHandlers[status] ? statusHandlers[status] : handleError;
        }

        function startPoll() {
            $timeout(function() {
                pollInterval = $interval(function() {
                    var queryParameters = $scope.loginOnBehalfOf ? {
                        loginOnBehalfOf: $scope.loginOnBehalfOf
                    } : {};
                    var collectResponse = BankIdService.authCollect(queryParameters, $scope.orderResponse);

                    collectResponse.$promise.then(function(response) {
                        handleResponse(response.status)(response);
                    }, function(response) {
                        var handler = handleResponse(response.data.status);
                        if (handler) {
                            handler(response.data);
                        } else {
                            handleError(response.data);
                        }
                    });

                }, pollIntervalMillis);
            }, pollSleepMillis);
        }

        $scope.authBid = function() {
            $scope.authStarted = true;
            resetMessages();
            displayBankIdMessage({
                status: 'OUTSTANDING_TRANSACTION'
            });
            var loginBidRequest = BankIdService.auth({
                username: $scope.username
            });

            loginBidRequest.$promise.then(function(response) {
                if (response.isError) {
                    handleResponse(response.status)(response);
                    return;
                }
                $scope.orderResponse = response.payload;

                if (!$scope.mobile || ($scope.mobile && $scope.form.openMbid)) {
                    launchBankIdApp();
                }
                startPoll();
            });
        };

        function hideLoader() {
            $rootScope.$broadcast('event:hideLoader');
        }

        $scope.$on('$destroy', cancelPoll);
    });
