angular.module('psModule')
    .service('TokenService', function(StorageService, $cookies, base64) {
      return {
          attachPsTokenToUrl: (url) => {
              // If logged in via MaxSecurity BankID there will be no psToken
              const DELIMITER = (url.indexOf('?') >= 0) ? '&' : '?';
              return url + (StorageService.getPsToken() ? DELIMITER + 'auth=' + StorageService.getPsToken(): '');
          },
          hasMaxSecurityAuth: () => {
              const prefix = prefixFromLocation();
              const token = `${prefix}.id_token`;
              const idTokenCookie = $cookies.get(token);
              return !!idTokenCookie;
          },
          getLoggedInUser() {
              try {
                  const prefix = prefixFromLocation();
                  const token = `${prefix}.id_token`;
                  const idTokenCookie = $cookies.get(token);
                  const parts = idTokenCookie.split('.');
                  if (parts.length === 3) {
                      // OK this seems to be a JWT, extract the payload part
                      // that contains the ID information
                      const idPart = parts[1];
                      const userInfoBase64 = base64.urldecode(idPart);
                      const userInfo = JSON.parse(userInfoBase64);
                      return {
                          userName: userInfo.name,
                          userId: userInfo.sub
                      }
                  }
              } catch (e) {
                  // Just fall through...
              }
              // No ID token found or exception, just return empty info
              return {
                  userName: null,
                  userId: null
              };
          }
      }
    })
    .service('LoginService', function($state, $transitions, $rootScope, $http, $cookies, ConfigService, StorageService, LogoutService, TokenService) {

        const login = function (token) {
            ConfigService.init(token);
            $rootScope.$emit('$rootScope:$emit:login');
            $state.go(LogoutService.previousState() || 'intro');
        };

        const isLoggedIn = () => (!!TokenService.hasMaxSecurityAuth() || !!StorageService.getPsToken()) && !!$rootScope.user;

        const isLoggingIn = () => ConfigService.isPending();

        return {
            login: login,
            isLoggedIn: isLoggedIn,
            isLoggingIn: isLoggingIn,
            isLoggedOut: () => !(isLoggedIn() || isLoggingIn()),
            maxSecLogout: () => $http.post("/ps/api/auth/logout"),
            getLastLoginSource: () => $cookies.get(prefixFromLocation() + ".last-login-source"), // valid values: "azure"|"bankid"; the "ps.{env}.last-login-source" cookie is set by se.maxm.privateservices.system.LoginResource
        };
    })
    .service('LogoutService', function($state, $http, $window, StorageService) {
        let prevState;

        const previousState = () => {
            return prevState ? prevState.name : undefined;
        };
        const isLoginState = (state) => !!state && state.name && state.name.indexOf('login') >= 0;

        const loggedOut = (state) => {
            if (!!state && !isLoginState(state)) {
                prevState = state;
            }

            StorageService.clear();
        };

        const logout = () => {
            const afterLogoutResponse = () => {
                loggedOut();
                $window.location.replace('/ps/api/auth/maxsec-logout');
            };
            return $http.post("/ps/api/auth/logout")
                .then(afterLogoutResponse, afterLogoutResponse);
        };

        return {
            isLoginState: isLoginState,
            loggedOut: loggedOut,
            logout: logout,
            previousState: previousState
        };
    }).run(($transitions, $state, LogoutService, LoginService, ConfigService, StorageService, $location, $translate) => {
        const lastLoginSource = LoginService.getLastLoginSource();
        const { sso, auth, source, toUrl, azureLoginCallback } = $location.search();
        const token = sso || auth || StorageService.getPsToken();
        let redirectToOther = sso && toUrl;

        $transitions.onBefore({}, function(trans) {
            // Guard to make sure that we wait for pending config to be resolved before proceeding to state requiring config
            const isLoggingIn = !LogoutService.isLoginState(trans.to()) && LoginService.isLoggingIn();
            if (isLoggingIn) {
                /* Return derived Promise resolved without value when pending config Promise is resolved
                   - this delays the current transition until init-config response has arrived without changing its target */
                return ConfigService.pendingConfig().then(() => {});
            }
        });

        $transitions.onStart({}, function(trans) {
            // Make sure that if you are logged out (session expired) and trying to goto protected page (non-login page) then redirect to the login page
            const redirectToLoginInstead = !LogoutService.isLoginState(trans.to()) && LoginService.isLoggedOut();
            if (redirectToLoginInstead) {
                LogoutService.loggedOut(trans.to());
                return trans.router.stateService.target('login');
            }

            // Change first page loaded to route given by toUrl if defined
            if (redirectToOther && !LoginService.isLoggedOut()) {
                redirectToOther = false;
                return trans.router.stateService.target(toUrl);
            }
        });

        if (sso) {
            if (azureLoginCallback !== "true") { // In case of azureLoginCallback === "true", just continue to ConfigService.init(token, source);
                if (lastLoginSource === "bankid") {
                    // We have arrived at PS after the jump-out from another application (RS, PML or PMLWeb),
                    // but we have a previous login from BankID that has not been fully logged out.
                    $translate("ps_obsolete_bankid_login")
                        .then(text => {
                            alert(text);
                            clearLoadingSpinner();
                            LogoutService.logout();
                        });
                    return;
                } else {
                    // We have arrived at PS after the jump-out from another application (RS, PML or PMLWeb)
                    // and there is **no** previous BankID login lingering.
                    window.location.replace("/ps/api/auth/azure-login?sso=" + sso + "&toUrl=" + toUrl);
                    return;
                }
            }
        } else if (!token && lastLoginSource === "azure") {
            // We have an incomplete internal login (that is probably obsolete)
            $translate("ps_obsolete_azure_login")
                .then(text => {
                    alert(text);
                    clearLoadingSpinner();
                    LogoutService.logout();
                })
            return;
        }

        ConfigService.init(token, source);
    }
);

function clearLoadingSpinner() {
    const loadingClass = 'deferred-bootstrap-loading';
    const bodyElement = angular.element(document.body);
    bodyElement.removeClass(loadingClass);
}

function prefixFromLocation() {
    const host = window.location.host;
    if (host.indexOf('local') >= 0) {
        return 'ps.local';
    } else if (host.indexOf('dev') >= 0) {
        return 'ps.dev';
    } else if (host.indexOf('sys') >= 0) {
        return 'ps.sys';
    } else if (host.indexOf('int') >= 0) {
        return 'ps.int';
    } else if (host.indexOf('stage') >= 0) {
        return 'ps.stage';
    }
    return 'ps';
}
