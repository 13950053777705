angular.module('psModule').directive('verifyIsNumber', [ function () {
    return {
        priority: -1,
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, modelCtrl) {
            modelCtrl.$parsers.push(function (inputValue) {
                var previousValue = modelCtrl.$modelValue || '';
                var nextViewValue = inputValue;
                if (!inputValue.match(/^[0-9]*$/)) {//OBS: Can give ""
                    nextViewValue = previousValue;
                    modelCtrl.$setViewValue(nextViewValue);
                    modelCtrl.$render();
                }
                return nextViewValue;
            });
        }
    };
}]);
