angular.module('psModule')
    .directive('psPositiveDecimalAmount', function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, ctrl) {
                let attributeName = "psPositiveDecimalAmount";
                let englishDecimalMark = '.';

                function isValidNumber(decimalAmount) {
                    return typeof decimalAmount === "number" && decimalAmount >= 0;
                }

                function isValidString(decimalAmount) {
                    if (typeof decimalAmount !== "string") {
                        return false;
                    }

                    if (decimalAmount.split(englishDecimalMark).length > 2) {
                        // Maximum one decimal mark is allowed in a decimal number
                        return false;
                    }

                    let positionOfDecimalMark = decimalAmount.indexOf(englishDecimalMark);
                    if (positionOfDecimalMark === 0 || positionOfDecimalMark === decimalAmount.length - 1) {
                        // The decimal mark is now allowed to be the first or last character
                        return false;
                    }

                    let inputWithoutDecimalMark = decimalAmount.replace(englishDecimalMark, '');
                    let regex = new RegExp('^[0-9]*$');
                    return regex.test(inputWithoutDecimalMark);
                }

                ctrl.$validators[attributeName] = function (input) {
                    if (ctrl.$isEmpty(input)) {
                        // Empty inputs should be validated with required or ng-required
                        return true;
                    }

                    return isValidNumber(input) || isValidString(input);
                };

                attrs.$observe(attributeName, function () {
                   ctrl.$validate();
                });
            }
        };
    })
    .directive('psDecimalPercentage', function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, ctrl) {
                let attributeName = "psDecimalPercentage";
                let englishDecimalMark = '.';

                function isValidNumber(decimalPercentage) {
                    if (typeof decimalPercentage !== "number") {
                        return false;
                    }

                    return decimalPercentage >= 0 && decimalPercentage <= 1;
                }

                function isValidString(decimalPercentage) {
                    if (typeof decimalPercentage !== "string") {
                        return false;
                    }

                    let noDecimalMarks = decimalPercentage.split(englishDecimalMark).length;
                    if (noDecimalMarks > 2) {
                        // Maximum one decimal mark is allowed in a decimal number
                        return false;
                    }

                    let positionOfDecimalMark = decimalPercentage.indexOf(englishDecimalMark);
                    if (positionOfDecimalMark === 0 || positionOfDecimalMark === decimalPercentage.length - 1) {
                        // The decimal mark is now allowed to be the first or last character
                        return false;
                    }

                    let inputWithoutDecimalMark = decimalPercentage.replace(englishDecimalMark, '');
                    let regex = new RegExp('^[0-9]*$');
                    if (!regex.test(inputWithoutDecimalMark)) {
                        // The input contains other characters than numbers and decimal marks
                        return false;
                    }

                    return parseFloat(decimalPercentage) <= 1;
                }

                ctrl.$validators[attributeName] = function (input) {
                    if (ctrl.$isEmpty(input)) {
                        // Empty inputs should be validated with required or ng-required
                        return true;
                    }

                    return isValidNumber(input) || isValidString(input);
                };

                attrs.$observe(attributeName, function () {
                    ctrl.$validate();
                });
            }
        };
    });
