angular.module('fondbyte')
    .controller('ConfirmCtrl', function ($scope, $rootScope, $state, $window, ChangeService, instrumentService, Message, $q, electronicFundChangeEnabled) {
        $scope.order = ChangeService.getReallocationOrder();

        if (!$scope.order) {
            $state.go('fondbyte');
            return;
        }

        $scope.branding = $scope.order.bolag;
        $scope.hasElectronic = electronicFundChangeEnabled;

        $scope.ps = {
            signing: !$scope.hasElectronic ? "m" : null,
            eSigningType: null,
            openMbid: $rootScope.isTouchDevice
        };

        $scope.isTouchDevice = $rootScope.isTouchDevice;
        $scope.isSigningStarted = false;
        $scope.submitted = false;
        $scope.electronicFundChangeOpen = true; // Start with true to prevent flashing of information box on loading.

        var init = function() {
            ChangeService.isElectronicFundChangeOpen().then(function (result) {
                $scope.electronicFundChangeOpen = result;
            });
        };

        // Initialize controller stuff
        init();

        $scope.signingStarted = function () {
            $scope.isSigningStarted = true;
        };

        $scope.isApproved = function () {
            if ($scope.ps.signing === 'e' && !$scope.isElectronicFundChangeOpen()) {
                Message.error('fb_fund_change_market_closed_err', 'fb_fund_change_market_closed_err_message', 'nochangesError');
                $window.scrollTo(0, 0);
                return false;
            } else if (!signingSelected()) {
                Message.error('fb_signing_selected_err', 'fb_signing_selected_err_message', 'noSigning');
                $window.scrollTo(0, 0);
                return false;
            }
            return true;
        };

        $scope.sendOrder = function (signature, signatureVisibleData, signatureToken, bankIdLogEntryId) {
            if ($scope.isApproved()) {
                $scope.submitted = true;
                ChangeService.sendOrder(signature, signatureVisibleData, signatureToken, bankIdLogEntryId)
                    .then(function (receipt) {
                        $state.go('fondbyteBekraftelse', {orgNr: $scope.order.orgNo, orderId: receipt.id});
                    }, function () {
                        $scope.isSigningStarted = false;
                    });
            }
        };
        $scope.instruments = {};
        $scope.getFund = function (sparfordeling) {
            if (sparfordeling.innehav.isin) {
                var key = sparfordeling.innehav.name.replace(/\s+/g, '');
                var inst = $scope.instruments[key];

                if (!angular.isUndefined(inst)) {
                    return inst;
                } else {
                    instrumentService.getInstrument($scope.order.instrumentGroupKey, sparfordeling.innehav.isin)
                        .then(function (i) {
                            var fund = angular.isUndefined(i) ? null : i;
                            if (!$scope.instruments.hasOwnProperty(key)) {
                                $scope.instruments[key] = fund;
                            }
                            return fund;
                        });
                }
            }
        };

        $scope.signingFailed = function () {
            $scope.isSigningStarted = false;
        };

        $scope.disableConfirm = function () {
            return !signingSelected() || $scope.submitted;
        };

        function signingSelected() {
            if ($scope.ps.signing === 'e') {
                return $scope.ps.eSigningType != null;
            }

            return $scope.ps.signing != null;
        }

        $scope.isElectronicFundChangeOpen = function() {
            return !!$scope.electronicFundChangeOpen;
        };

        $scope.$watchGroup(['ps.signing','ps.eSigningType'], function(){
            if (signingSelected()) {
                Message.resolveMessage('noSigning');
            }
        });
});
