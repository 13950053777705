angular.module('maxfonder')
    .controller('ConfirmationMaxfonderCtrl', function ($scope, $location, $filter, RegistrationService, OrderService, ConfirmationService) {

        $scope.pdf = null;
        $scope.pdfId = null;
        $scope.isPaper = null;
        $scope.registration = RegistrationService.getSavedRegistration();
        $scope.order = OrderService.getSavedOrder();

        checkPreconditionsOrElseRedirect();
        init();

        function checkPreconditionsOrElseRedirect() {
            if (!(isRegistrationAndOrder() || isRegistration() || isOrder())) {
                $location.path('/maxfonder');
            }
        }

        function init() {
            if (ConfirmationService.hasStoredRegistrationAndOrderBekraftelse()) {
                handleStoredRegistrationAndOrder();
                return;
            }

            if (ConfirmationService.hasStoredRegistrationBekraftelse()) {
                handleStoredRegistration();
                return;
            }

            if (ConfirmationService.hasStoredOrderBekraftelse()) {
                handleStoredOrder();
                return;
            }

            if (isSubmittedRegistration() && isSubmittedOrder()) {
                handleRegistrationAndOrder();
                return;
            }

            if (isSubmittedRegistration()) {
                handleRegistration();
                return;
            }

            if (isSubmittedOrder()) {
                handleOrder();
                return;
            }

        }


        $scope.enablePaper = function () {
            return $scope.isPaper;
        };

        var textsPrefix = 'maxfonder_bekraftelse_',
            texts = getTexts();
        $scope.title = getTitle(texts);
        $scope.infoText = getInfoText(texts);

        function getTitle(texts) {
            return texts ? texts.title : null;
        }

        function getInfoText(texts) {
            return texts ? texts.infoText : null;
        }

        function getTexts() {
            var suffix = function () {
                return $scope.isPaper ? '_paper' : '';
            };

            var texts = function (title, infoText) {
                return {
                    title: textsPrefix + title + suffix(),
                    infoText: textsPrefix + infoText + suffix()
                };
            };

            if (isRegistrationAndOrder()) {
                return texts(
                    'registration_and_order_title',
                    'registration_and_order_infotext');
            }

            if (isRegistration() && !isOrder()) {
                return texts(
                    'registration_title',
                    'registration_infotext');
            }

            if (isOrder() && !isRegistration()) {
                return texts(
                    'order_title',
                    'order_infotext');
            }

            return null;
        }


        function isSubmittedRegistration() {
            return $scope.registration && $scope.registration.submitted;
        }

        function isSubmittedOrder() {
            return $scope.order && $scope.order.submitted;
        }

        function isRegistrationAndOrder() {
            return (isSubmittedRegistration() && isSubmittedOrder()) || ConfirmationService.hasStoredRegistrationAndOrderBekraftelse();
        }

        function isRegistration() {
            return isSubmittedRegistration() || ConfirmationService.hasStoredRegistrationBekraftelse();
        }

        function isOrder() {
            return isSubmittedOrder() || ConfirmationService.hasStoredOrderBekraftelse();
        }


        function handleStoredRegistration() {
            var registrationBekraftelse = ConfirmationService.getStoredRegistrationBekraftelse();
            $scope.isPaper = registrationBekraftelse.isPaper;

            if ($scope.isPaper) {
                //The pdf for print needs to be regenerated after a page refresh
                RegistrationService.generatePDFForPrint(registrationBekraftelse.registrationDetails).then(
                    function (pdfResult) {
                        $scope.pdf = pdfResult.pdf;
                    }
                );
            } else {
                $scope.pdfId = registrationBekraftelse.registrationDetails.pdfId;
            }
        }

        function handleRegistration() {
            $scope.isPaper = !$scope.registration.eSigningSelected;

            if ($scope.isPaper) {
                $scope.pdf = $scope.registration.pdfResult.pdf;
            } else {
                $scope.pdfId = $scope.registration.pdfId;
            }

            //Stores values in case the user refreshes the page
            ConfirmationService.storeRegistrationBekraftelse($scope.registration, $scope.isPaper);
            RegistrationService.deleteRegistration();
        }


        function handleStoredOrder() {
            var orderBekraftelse = ConfirmationService.getStoredOrderBekraftelse();
            $scope.isPaper = orderBekraftelse.isPaper;

            if ($scope.isPaper) {
                //The pdf for print needs to be regenerated after a page refresh
                OrderService.generatePDFForPrint(orderBekraftelse.orderDetails).then(
                    function (pdfResult) {
                        $scope.pdf = pdfResult.pdf;
                    }
                );
            } else {
                $scope.pdfId = orderBekraftelse.orderDetails.pdfId;
            }
        }

        function handleOrder() {
            $scope.isPaper = !$scope.order.eSigningSelected;

            if ($scope.isPaper) {
                $scope.pdf = $scope.order.pdfResult.pdf;
            } else {
                $scope.pdfId = $scope.order.pdfId;
            }

            //Stores values in case the user refreshes the page
            ConfirmationService.storeOrderBekraftelse($scope.order, $scope.isPaper);
            OrderService.deleteOrder();
        }


        function handleStoredRegistrationAndOrder() {
            var bekraftelse = ConfirmationService.getStoredRegistrationAndOrderBekraftelse();
            $scope.isPaper = bekraftelse.isPaper;

            if ($scope.isPaper) {
                //The pdf for print needs to be regenerated after a page refresh
                OrderService.generatePDFForPrint(bekraftelse.orderDetails, bekraftelse.registrationDetails).then(
                    function (pdfResult) {
                        $scope.pdf = pdfResult.pdf;
                    }
                );
            } else {
                $scope.pdfId = bekraftelse.orderDetails.pdfId;
            }
        }

        function handleRegistrationAndOrder() {
            $scope.isPaper = !$scope.order.eSigningSelected;

            if ($scope.isPaper) {
                $scope.pdf = $scope.order.pdfResult.pdf; // the merged PDF for registration and order is stored in order.pdfResult.pdf
            } else {
                $scope.pdfId = $scope.order.pdfId; // the merged PDF-id for registration and order is stored in order.pdfId
            }

            //Stores values in case the user refreshes the page
            ConfirmationService.storeRegistrationAndOrderBekraftelse($scope.registration, $scope.order, $scope.isPaper);
            OrderService.deleteOrder();
            RegistrationService.deleteRegistration();
        }


    }).factory('ConfirmationService', function () {
        var service = {};

        service.hasStoredRegistrationAndOrderBekraftelse = function () {
            return sessionStorage.maxfonderRegistrationAndOrderBekraftelse !== undefined;
        };

        service.hasStoredRegistrationBekraftelse = function () {
            return sessionStorage.maxfonderRegistrationBekraftelse !== undefined;
        };

        service.hasStoredOrderBekraftelse = function () {
            return sessionStorage.maxfonderOrderBekraftelse !== undefined;
        };


        service.storeRegistrationAndOrderBekraftelse = function (registration, order, isPaper) {
            var bekraftelse = {
                isPaper: isPaper,
                registrationDetails: registration,
                orderDetails: order
            };
            sessionStorage.maxfonderRegistrationAndOrderBekraftelse = JSON.stringify(bekraftelse);
        };

        service.storeRegistrationBekraftelse = function (registration, isPaper) {
            var bekraftelse = {
                isPaper: isPaper,
                registrationDetails: registration
            };
            sessionStorage.maxfonderRegistrationBekraftelse = JSON.stringify(bekraftelse);
        };

        service.storeOrderBekraftelse = function (order, isPaper) {
            var bekraftelse = {
                isPaper: isPaper,
                orderDetails: order
            };
            sessionStorage.maxfonderOrderBekraftelse = JSON.stringify(bekraftelse);
        };


        service.getStoredRegistrationAndOrderBekraftelse = function () {
            return JSON.parse(sessionStorage.maxfonderRegistrationAndOrderBekraftelse);
        };

        service.getStoredRegistrationBekraftelse = function () {
            return JSON.parse(sessionStorage.maxfonderRegistrationBekraftelse);
        };

        service.getStoredOrderBekraftelse = function () {
            return JSON.parse(sessionStorage.maxfonderOrderBekraftelse);
        };


        service.reset = function () {
            sessionStorage.removeItem('maxfonderRegistrationAndOrderBekraftelse');
            sessionStorage.removeItem('maxfonderRegistrationBekraftelse');
            sessionStorage.removeItem('maxfonderOrderBekraftelse');
        };


        return service;
    });
