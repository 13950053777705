angular.module('psModule').directive('psTotalPercentage', ['$filter', function($filter) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            value: '='
        },
        template:'<div><b ng-if="value == 100" class="text-default">{{value | percentage:0:true}}</b> <b ng-if="value != 100" class="text-danger">{{value | percentage:0:true}}</b></div>'
    };
}]);
