angular.module('psModule').factory('activeFundChangeService', function ($rootScope, $http, $q) {
    var service = {};

    service.isElectronicFundChangeEnabled = function (orgNo) {
        var defer = $q.defer();
        var enabledPromise = defer.promise;
        var insuranceCompany = $rootScope.config.insuranceCompaniesConfig.companies[$rootScope.config.shortcodes[orgNo]];
        if (insuranceCompany && insuranceCompany.electronic) {
            $http.get('/ps/api/insurancecompany/' + orgNo + '/fundchange_enabled').then(function (response) {
                defer.resolve((/^true$/i).test(response.data));
            });
        } else {
            defer.resolve(false);
        }

        return enabledPromise;
    };

    return service;
});
