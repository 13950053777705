angular.module('psModule').factory('fundChangeableService', function ($q, $resource, $rootScope) {
    var resource = $resource('/ps/api/fundchangeable', {}, {
        'getInsurances': {method: 'GET', params: {}, isArray: true}
    });

    var future = $q.defer();
    var promise = future.promise;

    resource.getInsurances().$promise.then(function (insurances) {
        _.each(insurances, function(i) {
            i.companyShortCode = $rootScope.config.shortcodes[i.orgNo];
        });

        future.resolve(insurances);
    }, function (error) {
        future.reject(error);
    });

    var fundChangeableService = {};

    fundChangeableService.getFundChangeableInsurances = function () {
        return promise;
    };

    return fundChangeableService;

});
