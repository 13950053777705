angular.module('psModule').directive('psColumnChart', function($window, $filter, $rootScope) {
	return {
		restrict: 'E',
        replace: true,
        scope: {
            chartSeries: '=',
            chartCategories: '=',
            incomeTaxLimitBefore65: '=',
            incomeTaxLimitFrom65: '=',
            showTooltip: '@',
            id: '@'
        },
        template: '<div style="margin: 0 auto"></div>',
        link: function (scope, element, attrs) {

            Highcharts.setOptions({
                lang: {
                    decimalPoint: ',',
                    thousandsSep: ' ',
                    numericSymbols: []
                }
            });

            function getChart() {
                return new Highcharts.Chart({
                    chart: {
                        renderTo: scope.id,
                        type: 'column',
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        height: $window.innerWidth < 800 ? 300 : 400
                    },
                    title: {
                        text: ''
                    },
                    legend: {
                        reversed: true,
                        labelFormatter: function() {
                            return $filter('translate')(this.name);
                        }
                    },
                    xAxis: {
                        categories: scope.chartCategories,
                        title: {
                            text: $filter('translate')('sim_chart_xaxis') + ' [' + $filter('translate')('sim_chart_xaxis_unit') + ']'
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: $filter('translate')('sim_chart_yaxis') + ' [' + $filter('translate')('sim_chart_yaxis_unit') + ']'
                        },
                        plotLines: [
                            {
                                color: scope.incomeTaxLimitFrom65.color,
                                dashStyle: 'solid',
                                value: scope.incomeTaxLimitFrom65.value,
                                width: 2,
                                zIndex: 5
                            },
                            {
                                color: scope.incomeTaxLimitBefore65.color,
                                dashStyle: 'dash',
                                value: scope.incomeTaxLimitBefore65.value,
                                width: 2,
                                zIndex: 5
                            }
                        ]
                    },
                    tooltip: {
                        enabled: true,
                        formatter: function () {
                            if (scope.showTooltip === 'true') {
                                var sum = 0;
                                var tooltip = '<span><strong>' + $filter('translate')('sim_chart_xaxis') + ': ' + this.x + ' ' + $filter('translate')('sim_chart_xaxis_unit') + '</strong></span><br/>';
                                for (var i = this.points.length - 1; i >= 0; i--) {
                                    sum += this.points[i].y;
                                    tooltip += '<span style="color:' + this.points[i].series.color + '">' + $filter('translate')(this.points[i].series.name) + '</span>: <b>' + Highcharts.numberFormat(this.points[i].y, 0, ',', ' ') + '</b> (' + Highcharts.numberFormat(this.points[i].percentage, 0) + ' %)<br/>';
                                }
                                tooltip += '<br/><span><strong>' + $filter('translate')('sim_chart_tooltip_sum') + ': ' + Highcharts.numberFormat(sum, 0, ',', ' ') + '</strong></span>';
                                return tooltip;
                            } else {
                                return false;
                            }
                        },
                        shared: true
                    },
                    plotOptions: {
                        column: {
                            stacking: 'normal',
                            pointPadding: -0.1
                        }
                    },
                    series: scope.chartSeries
                });
            }

            var chart = getChart();

            var refreshTranslations = $rootScope.$on('$translateChangeSuccess', function() {
                var chart = getChart();
                chart.redraw();
            });

            scope.$on("$destroy", function() {
                chart = undefined;
                refreshTranslations();
            });

            scope.$on("redraw", function() {
                scope.$watchCollection(scope.chartSeries, function() {
                    chart.xAxis[0].setCategories(scope.chartCategories, false);
                    var curLen = chart.series.length;
                    var newLen = scope.chartSeries.length;
                    if (newLen > curLen) {
                        chart.addSeries(scope.chartSeries[newLen - 1], false, false);
                    } else if (newLen < curLen) {
                        chart.series[0].remove();
                    }
                    for (var i = 0; i < newLen; i++) {
                        chart.series[newLen-i-1].setData(scope.chartSeries[i].data, false);
                    }
                    chart.redraw();
                }, true);
            });
        }
    };
});
