angular.module('psModule').directive('psPerformance', [function() {
	return {
		restrict: 'E',
		replace: true,
		scope: {
            value: '=',
            percent: '=?'
		},
		template:'<span class="no-line-break" ng-class="{\'text-success\': value > 0, \'text-danger\': value < 0}">{{value ? (value | number:2) : \'-\'}}{{ value && percent ? \' %\' : \'\'}}</span>'
	};
}]);
