angular.module('psModule').controller('DocumentsDropCtrl',
    function ($scope, $rootScope, $filter, DocumentStatefulService, Message, Upload, $q) {

        $scope.readyToUpload = false;
        $scope.uploading = false;
        $scope.progressPercentage = 0;
        $scope.types = {};
        $scope.descriptions = {};
        $scope.errorMessages = {};
        $scope.flashMessages = {};

        const DUPLICATE_NAME = 'ps_documents_duplicate_name';
        const ONLY_PDF = 'ps_documents_onlypdf';
        const MISSING_TYPE = 'ps_documents_missing_type';
        const MISSING_DESCRIPTION = 'ps_documents_missing_description';
        const FILE_TOO_LARGE = 'ps_documents_file_too_large';
        const MAX_FILE_SIZE = 50000000;
        const FLASH_MSG_MILLISECONDS = 500;
        const FLASH_MSG_REMOVE_FILE_PENDING = "remove file pending";

        $scope.$watch('files', function () {
            $scope.readyToUpload = $scope.files && $scope.files.length > 0;
            $scope.progressPercentage = 0;
            if($scope.files){
                for (let i = 0; i < $scope.files.length; i++) {
                    let file = $scope.files[i];
                    // ngFileUpload borde fixa detta via pattern attributet. Men laddar man en fil via
                    // browse, inte drag and drop, så valideras inte filtyp.
                    if(!validators(file, ONLY_PDF)){
                        addErrorMessage(file.$$hashKey, ONLY_PDF)
                    }
                }
            }
        });


        $scope.$watch('file', function () {
            if ($scope.file != null) {
                $scope.files = [$scope.file];
            }
        });

        function validators(file, key){
            switch(key){

                case ONLY_PDF:
                    return file.name.endsWith('pdf');
                case MISSING_TYPE:
                    return $scope.types[file.$$hashKey] !== undefined;
                case MISSING_DESCRIPTION:
                    if($scope.descriptions[file.$$hashKey]){ // if truthy
                        return true;
                    }else{
                        return false;
                    }
                case FILE_TOO_LARGE:
                    //TODO sätter en gräns på 3MB, ska parameter styras
                    return file.size < MAX_FILE_SIZE;
                default:
                    console.log("Unknown validator" + key);
                    return false;
            }
        }

        function clearErrorMessage(fileName, key){
            if($scope.errorMessages[fileName]){
                let index = $scope.errorMessages[fileName].indexOf(key);
                if (index > -1) {
                    $scope.errorMessages[fileName].splice(index, 1);
                }
            }
        }

        function addErrorMessage(fileName, key){
            if($scope.errorMessages[fileName] !== undefined){
                $scope.errorMessages[fileName].push(key)
            }else{
                $scope.errorMessages[fileName] = [key];
            }
        }

        $scope.selectedType = function(f, item) {
            $scope.types[f.$$hashKey] = item;
            clearErrorMessage(f.$$hashKey, MISSING_TYPE)
        };

        $scope.addDescription = function(f, description) {
            $scope.descriptions[f.$$hashKey] = description;
            clearErrorMessage(f.$$hashKey, MISSING_DESCRIPTION);
        };

        function asyncFlashMsg(fileName, msg) {
            $scope.flashMessages[fileName] = msg;
            let deferred = $q.defer();
            setTimeout(function() {
                deferred.resolve(fileName, msg);
            }, FLASH_MSG_MILLISECONDS);
            return deferred.promise;
        }

        $scope.remove = function(f) {
            let index = $scope.files.indexOf(f);
            if (index > -1) {
                asyncFlashMsg(f.asyncFlashMsg, FLASH_MSG_REMOVE_FILE_PENDING).then(function(fileName, msg){
                    $scope.files.splice(index, 1);
                    $scope.files = $scope.files.slice(0); // clone to trigger change
                    delete $scope.flashMessages[fileName]; // only supporting FLASH_MSG_REMOVE_FILE_PENDING
                });
            }
        };

        $scope.validate = function(files){
            $scope.errorMessages = {};
            let uniqueNames = [];
            let valid = true;
            for (let i = 0; i < files.length; i++) {
                let f = files[i];
                if(uniqueNames.includes(f.name)){
                    addErrorMessage(f.$$hashKey, DUPLICATE_NAME);
                    valid = false;
                }
                uniqueNames.push(f.name);

                if(!validators(f, MISSING_TYPE)){
                    addErrorMessage(f.$$hashKey, MISSING_TYPE);
                    valid = false;
                }
                if(!validators(f, MISSING_DESCRIPTION)){
                    addErrorMessage(f.$$hashKey, MISSING_DESCRIPTION);
                    valid = false;
                }
                if(!validators(f, FILE_TOO_LARGE)){
                    addErrorMessage(f.$$hashKey, FILE_TOO_LARGE);
                    valid = false;
                }
                if(!validators(f, ONLY_PDF)){
                    addErrorMessage(f.$$hashKey, ONLY_PDF);
                    valid = false;
                }
            }
            return valid;
        };

        $scope.upload = function (files) {
            if(!$scope.validate(files)){
                return;
            }
            if (files && files.length) {
                $scope.uploading = true;
                for (var i = 0; i < files.length; i++) {
                    var file = files[i];
                    if (!file.$error) {
                        uploadFile(file);
                    }
                }
            }
        };

        function uploadFile(file){
            Upload.upload({
                url: '/ps/api/documents/customerDocument',
                data: {
                    username: $scope.username,
                    file: file,
                    name: file.name,
                    type: $scope.types[file.$$hashKey],
                    description: $scope.descriptions[file.$$hashKey],
                    notification: file.notification
                }
            }).then($scope.onUploadCompete, $scope.onUploadError, $scope.uploadProgress);
        }

        $scope.onUploadCompete = function(resp){
            $scope.files = [];
            $scope.uploading = false;
            DocumentStatefulService.setReload();
            $scope.$emit('onUploadCompete', "complete");
        };

        $scope.onUploadError = function(error){
            Message.error(error.data.message, 'ps_documents_error_upload_failed');
        };

        $scope.uploadProgress = function(evt){
            let progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            $scope.progressPercentage = progressPercentage;
        };

        $scope.init = function(){
        };

        $scope.reset = function(){
            $scope.files = [];
            $scope.errorMessages = {};
            $scope.descriptions = {};
            $scope.types = {};
        };

        $scope.$on('$destroy', function () {
        });
    }
);
