angular.module('psModule').directive('psAmount', function() {
	return {
		restrict: 'E',
        scope: {
            value: '=',
            currency: '@',
            renderOnce: '@'
        },
        template: '<span ng-if="renderOnce">{{::amount | number:0}}{{currency ? "&nbsp;"+currency : ""}}</span>' +
                  '<span ng-if="!renderOnce">{{amount | number:0}}{{currency ? "&nbsp;"+currency : ""}}</span>',
        link: function(scope, element, attrs) {
            scope.$watch('value', function() {
                scope.amount = 0;
                if (scope.value) {
                    if (_.isNumber(scope.value.value)) {
                        scope.amount = scope.value.value;
                    } else {
                        scope.amount = scope.value;
                    }
                }
            });
        }
	};
});
