angular.module('psModule', [
        'ui.bootstrap',
        'ui.bootstrap.accordion',
        'ui.router',
        'ngAnimate',
        'ngResource',
        'ngMessages',
        'ngSanitize',
        'ngCookies',
        'ngFileUpload',
        'pascalprecht.translate',
        'psBankId',
        'psUi',
        'rzSlider',
        'fondbyte',
        'utf8-base64',
        'ui-rangeSlider',
        'mmMessages',
        'mmTableComponents',
        'resources.ui.validation',
        'maxfonder'
    ])
    .config(function($provide) {
        $provide.value("mmMessages", mmMessages);
        mmMessages.load();
    })
    .config(['$qProvider', function($qProvider) {
        $qProvider.errorOnUnhandledRejections(false);
    }])
    .run(function($rootScope) {
        $rootScope.safeApply = function(fn) {
            var phase = $rootScope.$$phase;
            if (phase === '$apply' || phase === '$digest') {
                if (fn && (typeof(fn) === 'function')) {
                    fn();
                }
            } else {
                this.$apply(fn);
            }
        };

        $rootScope.checkDefined = function(e, path) {
            if (typeof e === 'undefined') {
                return false;
            }

            var props = path.split('.');

            for (var i = 0; i < props.length; i++) {

                var prp = props[i],
                    val = e[prp];

                if (typeof val === 'undefined') {
                    return false;
                } else if (val === null) {
                    return i === props.length - 1;
                } else {
                    e = val;
                }
            }
            return true;
        };
    });
