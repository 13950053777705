angular.module('psModule').controller('ProfilePageCtrl',
    function(fakta, meeting, latestMeeting, salary,
             $scope, $rootScope, $uibModal, $translate){
    if (fakta) {
        $scope.faktainsamling = fakta;
        $scope.customerFullname = fakta.basicInformation.firstName + " " + fakta.basicInformation.surname;
        $scope.streetAddress = fakta.basicInformation.streetAddress;
        $scope.zipCodeCity = fakta.basicInformation.zipCode + " " + fakta.basicInformation.city;
        $scope.email = fakta.basicInformation.email;
        $scope.phoneNumber = fakta.basicInformation.phoneNumber;
    }

    $scope.meeting = meeting && meeting.data;
    $scope.latestMeeting = latestMeeting && latestMeeting.data;
    $scope.openFaktainsamling = function(){
        if ($rootScope.config) {
            let language = $translate.use();
            $scope.fiLinkUrl = $rootScope.config.faktainsamlingurl + "?lang=" + language;
            window.open($scope.fiLinkUrl,  "_self" );

        } else {
            console.error("Saknar url till faktainsamlingen!");
        }
    };

    $scope.openProfilePage = function(){
        if ($rootScope.config) {
            let language = $translate.use();
            $scope.myprofileurl = $rootScope.config.myprofileurl + "?lang=" + language;
            window.open($scope.myprofileurl,"_self");
        } else {
            console.error("Saknar url till profilsidan!");
        }
    };
 });
