angular.module('psModule')
    .factory('UserService', function ($http) {
        return {
            meeting: function() {
                return $http.get('/ps/api/user/meeting');
            },
            latestMeeting: function() {
                return $http.get('/ps/api/user/meeting/latest');
            },
            getUserFIUrl: function (){
                return $http.get('/ps/api/user/fiurl');
            }
        };
    });
