angular.module('psModule').directive('loading', function($http, $timeout) {
	return {
		restrict: 'E',
        template:'<div id="loadingOverlay" ng-cloak><div id="loading" class="text-muted fa-2x text-center"><span><i class="fa fa-spinner fa-spin"></i><br><strong>{{messageKey | translate}}</strong></span></div></div>',
		link: function(scope, element, attrs) {
            element.hide();
            scope.messageKey  = attrs.messageKey || 'ps_loading';
            scope.isLoading = function () {
                if ($http.pendingRequests.length > 0) {
                    if (_.find) {
                        var nonBankid = _.find($http.pendingRequests, function(req){ return req.url.indexOf("./api/bankid") > -1; });
                        return nonBankid === undefined;
                    }
                    return true;

                }
                return false;
            };

            $timeout(function() {
                scope.$watch(scope.isLoading, function (v) {
                    if(v) {
                        element.show();
                    }else{
                        element.hide();
                    }
                });
            }, 700);

		}
	};
}).
directive('login', function() {
    return {
        restrict: 'E',
        template:'<div id="loadingOverlay" ng-cloak><div id="loading" class="text-muted fa-2x text-center"><span><i class="fa fa-spinner fa-spin"></i><br><strong>{{messageKey | translate}}</strong></span></div></div>',
        link: function(scope, element, attr) {
            scope.messageKey = attr.label;
            element.show();
        }
    };
});
