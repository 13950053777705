angular.module('psModule').controller('MultipleButtonsModalCtrl', ['$scope', '$modalInstance','header','body', 'buttonArray', 'headerIcon' ,function($scope, $modalInstance, header,  body, buttonArray, headerIcon ){

    $scope.header = header;
    $scope.body = body;
    $scope.buttonArray = buttonArray;
    $scope.headerIcon = headerIcon;

    $scope.buttonClicked = function(button) {
        $modalInstance.close(buttonArray.indexOf(button));
    };


}]);
