angular.module('psModule').directive('psLoginImage', () => {
    return {
        restrict: 'E',
        replace: true,
        template:'<img src="/ps/resources/img/ps_login.jpg" alt="Login image" class="img-responsive hidden" style="margin: 0 auto;">',
        link: (scope, element) => {
            scope.$watch('isLoggedOut()', (isLoggedOut) => {
                if (isLoggedOut) {
                    element.removeClass('hidden');
                } else {
                    element.addClass('hidden');
                }
            });
        }
    };
});
