angular.module('psModule').directive('psGraphPortfolioDevelopment', function($filter, GraphService){
        return {
            restrict: 'E',
            replace: false,
            scope: {
                portfolio: '=',
                benchmark: '=',
                id: '@'
            },
            link: function (scope, element, attrs) {
                var colours = GraphService.getGraphColours();
                var chart = new Highcharts.Chart({
                    chart: {
                        type: 'line',
                        width: 300,
                        height: 238,
                        renderTo: scope.id,
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        backgroundColor: null,
                        zoomType: 'x'
                    },
                    title: {
                        text: null
                    },
                    tooltip: {
                        enabled: true,
                        crosshairs: true,
                        formatter: function() {
                            return  '<b>' + this.series.name +'</b><br/>' +
                                Highcharts.dateFormat('%Y-%m-%d', new Date(this.x)) + ': ' + this.y + '%';
                        }
                    },
                    xAxis: {
                        type: 'datetime',
                        tickInterval: 3600 * 24 * 182.5,
                        title: {
                            text: null
                        },
                        dateTimeLabelFormats : {
                            week: '%Y-%m',
                            month: '%Y-%m',
                            year: '%Y-%m'
                        }
                    },
                    yAxis: {
                        title: {
                            text: null
                        },
                        labels: {
                            formatter: function () {
                                return this.value + '%';
                            }
                        },
                        tickInterval: 10
                    },
                    plotOptions: {
                        spline: {
                            marker: {
                                enabled: false
                            }
                        }
                    },
                    series: [{
                        name: $filter('translate')('ps_portfolio_graph_series_portfolio'),
                        data: scope.portfolio,
                        color: colours[0],
                        marker: {
                            enabled: false
                        }
                    }]
                });

                scope.$on("$destroy", function() {
                    chart = undefined;
                });

                scope.$watch('portfolio', function () {
                    chart.series[0].setData(scope.portfolio);
                    chart.redraw();
                });
            }
        };
    });
