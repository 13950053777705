angular.module('psModule').controller('CmsContentCtrl', function ($scope, $state, contentfileFilter) {

    function getCmsFileFromRoute() {
        if ($state.current && $state.current.data) {
            return $state.current.data.cmsfile || '';
        }
    }

    $scope.cmsContent = function cmsContent() {
        var file = getCmsFileFromRoute();
        return contentfileFilter(file);
    };
});
