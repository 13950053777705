angular.module('psModule')
    .factory('GraphService', function () {

        var lightBlue = '#A0DDFF';
        var graphColours = ['#003A78', '#FFAD00', '#8B8B8B', lightBlue, '#FFDB00', '#DCDCDC', '#00DA93', '#FFF5A5', '#4679BC', '#A5FFC8', '#000000 '];

        var setGraphDefaultValues = function () {
            var options = Highcharts.getOptions();
            options.plotOptions.pie.colors = graphColours;
            options.credits.enabled = false;
            options.lang.decimalPoint = ',';
            options.lang.thousandsSep = ' ';
        };

        var getGraphColours = function () {
            return graphColours;
        };

        var getLightBlue = function () {
            return  lightBlue;
        };

        return {
            setGraphDefaultValues: setGraphDefaultValues,
            getGraphColours: getGraphColours,
            getLightBlue: getLightBlue
        };

    });
