angular.module('psModule').directive('psPremiumTable', function() {
    return {
        restrict: 'E',
        replace: false,
        scope: {
            premiums: '='
        },
        template:'<div class="well info"><div class="mm-panels"><table class="table table-striped table-responsive"><thead><tr><th translate="ps_premiums_table_header_insuranceCompany"></th><th translate="ps_premiums_table_header_insuranceNumber"></th><th translate="ps_premiums_table_header_premium" class="text-right"></th><th translate="ps_premiums_table_header_frequency"></th><th translate="ps_premiums_table_header_premiumPerYear" class="text-right"></th></tr></thead><tbody><tr ng-repeat="premium in premiums | orderBy: \'insuranceCompany\' track by $index"><td>{{premium.insuranceCompany ? premium.insuranceCompany : \'ps_Unknown\' | translate}}</td><td>{{premium.insuranceNumber}}</td><td class="text-right no-line-break">{{premium.premium.value | number:0}}</td><td><span ng-if="premium.premium.frequency">{{!!premium.premium.frequency.occurrence && premium.premium.frequency.occurrence > 1 ? premium.premium.frequency.occurrence : \'\'}} <span translate="ps_betalningstermin-{{premium.premium.frequency.frequencyType}}"></span></span> <span ng-if="!premium.premium.frequency" translate="ps_betalningstermin-NONE"></span></td><td class="text-right no-line-break">{{!!premium.premiumByYear ? premium.premiumByYear : 0 | number:0}}</td></tr><tr ng-if="premiums.length == 0"><td colspan="5s"><span translate="ps_premiums_empty"></span></td></tr></tbody><tfoot><tr><td colspan="5" class="text-right no-line-breaks"><strong><span translate="ps_sum"></span>: {{totalPremiumPerYear | number:0}}</strong></td></tr></tfoot></table></div></div>',
        link: function(scope) {
            scope.totalPremiumPerYear = _.reduce(scope.premiums, function (total, premium) {
                return !!premium.premiumByYear ? total + premium.premiumByYear : total;
            }, 0);
        }
    };
});
